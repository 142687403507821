export default {
  setAllCoupons: (state, data) => {
    state.allCoupons = data.content;
    state.couponTotalPages = data.last_page;
  },
  setOneCoupon: (state, data) => {
    state.oneCoupon = data.content;
  },
  // update the coupon with the new data
  UpdateCoupon: (state, data) => {
    if (state.allCoupons) {
      const index = state.allCoupons.find((item) => item.id == data.id);
      if (index) {
        Object.assign(index, {...data,type_reduction:JSON.stringify(data.type_reduction)});
      }
    }
    state.oneCoupon = data;

  },
  // remove that coupon from the list and close delete confirmation dialog.
  deleteCoupon: (state, couponId) => {
    var index = state.allCoupons.findIndex((item) => item.id === couponId);
    if (index > -1) {
      state.allCoupons.splice(index, 1);
      state.deleteDialog = false;
    }
  },
  setDeleteDialog: (state, data) => {
    state.deleteDialog = data;
  }
};
