export default function ({ $axios, app, store }) {
  // setting locale and auth headers
  $axios.onRequest((config) => {
    app.$cookies;
    config.headers.common['locale'] = app.i18n.locale
    let loginData = app.$cookies.get("super-login-data", { parseJSON: true });
    if (loginData) {
      config.headers.common["X-Authorization"] = "Bearer " + loginData.token;
    }
    return config;
  });
  // handle various error statuses. It includes logic to handle different status codes, such as 400, 401, 404, 422, and 500. It also checks for a specific URL and handles it differently. It then returns a rejected promise
  $axios.onResponse((response) => {
    if (response.status === 200) {
      setTimeout(() => {
        store.commit("app/loadingFinish", null, { root: true });
      }, 100);
    }
    return response;
  });
  $axios.onError((error) => {
    setTimeout(() => {
      store.commit("app/loadingFinish", null, { root: true });
    }, 100);
    if (error.response.status == 400) {
      store.commit(
        "app/failMessage",
        error.response.data.data.code ? error.response.data.data.code : "error"
      );
    }

    if (error.response.status == 401) {
      store.dispatch("auth/logout");
      store.commit("app/failMessage", error.response.status);
    }
    if (
      error.response.status == 404 ||
      error.response.status == 422 ||
      // error.response.status == 403 ||
      error.response.status == 500
    ) {
      store.commit("app/failMessage", error.response.status);
    }
    return Promise.reject(error);
  });
}
