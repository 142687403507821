export default {
  // login with email and   set authentication token , load user data and  redirect
  async tryLoginAction({ commit ,dispatch}, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .post(`login`, data)
      .then((respo) => {
        if (respo.status === 200) {
          var responseData = respo.data.data.content;
          this.$axios.defaults.headers.common[
            "X-Authorization"
          ] = `Bearer ${responseData.token}`;

          this.$cookies.set(
            "super-login-data",
            JSON.stringify(respo.data.data.content),
            {
              // parseJSON: false,
              // domain:  process.env.cookieDomain,
              // secure: true,
              path: '/',
              // httpOnly:true,
              // sameSite: true
            }
          );
          let loginData = this.$cookies.get("super-login-data", {
            parseJSON: true,
          });
          if (loginData) {
            if (
              this.$axios.defaults.headers.common["X-Authorization"] === undefined
            ) {
              this.$axios.defaults.headers.common["X-Authorization"] =
                "Bearer " + loginData.token;
            }
            // commit("setLoggedInuser", loginData);
            dispatch('loadUserDetail');
          //  this.$router.push({
          //     path: "/" + this.app.i18n.locale + "/dashboard",
          //   });
            this.$router.go();

          }

          
        }
      })
      
  },
  // check if user is logged in and load data if not exist
  async checkLoggedIn({ commit ,dispatch}) {
    let loginData = this.$cookies.get("super-login-data", { parseJSON: true });
    if (loginData) {
      if (this.$axios.defaults.headers.common["X-Authorization"] === undefined) {
        this.$axios.defaults.headers.common["X-Authorization"] =
          "Bearer " + loginData.token;
      }
      await dispatch('loadUserDetail');
    }
  },
  // logout and remove cookies
  async logout({ commit }) {
    this.$cookies.removeAll();

    this.$cookies.remove("super-login-data", {
      domain: process.env.cookieDomain,
      path: "/",
      secure: true,
      sameSite: true,
    });
    this.$cookies.remove("super-login-data", {
      domain: process.env.cookieDomain,
      path: "/en",
      secure: true,
      sameSite: true,
    });

    this.$cookies.remove("super-login-data", {
      domain: process.env.cookieDomain,
      path: "/ar",
      secure: true,
      sameSite: true,
    });

    let loginData = this.$cookies.get("super-login-data", { parseJSON: true });
    if (!loginData) {
      this.$axios.defaults.headers.common["X-Authorization"] = undefined;

      commit("setLoggedOutuser");
      this.$router.push({ path: "/" + this.app.i18n.locale + "/login" });
    }
  }, 
  // send forgot password link
  async forgot_password({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    return await this.$axios
      .post(`forgot-password`, data)
      .then((respo) => {
        

        if (respo.status === 200) {
          commit("setCheckEmail", true);
        }
      })
      
  },
  // reset password and redirect to login
  async reset_password({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .post(`reset-password`, data)
      .then((respo) => {
        if (respo.status === 200) {
            commit("app/successMessage", "PasswordResetSuccess", {
              root: true,
            });
            this.$router.push({ path: "/" + this.app.i18n.locale + "/login" });
       

          
        }
      })
      .catch((error) => {
        if (error.response.status == 400) {
          if (error.response.data.data.code == "01_07") {
            this.$router.push({
              path: "/" + this.app.i18n.locale + "/restore-account",
            });
          }
        }
        throw error;
      });
      
  }, 
  async changeAddedTax({ commit }, data) {
    commit("app/loadingStart", null, { root: true });
    await this.$axios
      .post(`change-added-tax`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setUpdateDialog", false);
          commit("app/successMessage", "taxChanged", { root: true });

          
        }
      })
      
  }, 
  // change password
  async changePassword({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .post(`change-password`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("updatePassword");
        }
      })
      
  },

  async loadUserDetail({ commit }) {
    commit('app/loadingStart', null, { root: true });

 return  await this.$axios.get(`auth-admin`, null).then((respo) => {
      if (respo.status === 200) {
      
        commit('setLoggedInuser', respo.data.data.content);
        return respo.data.data.content;
      }
    });
  },
};
