export default {
  
  getAllCoupons(state) {
    return state.allCoupons;
  },
  getCouponTotalPages(state) {
    return state.couponTotalPages;
  },
  getOneCoupon(state) {
    return state.oneCoupon;
  },
  getDeleteDialog(state) {
    return state.deleteDialog;
  },
};
