export default {
  async loadGoogleReviewsRequests({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `google-reviews-branches`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAllGoogleReviewRequestes", respo.data);

          
        }
      })
      
  },
  async changeRequestStatus({ commit }, data) {
    commit("app/loadingStart", null, { root: true });
    commit("setFinishDialog", true);

    await this.$axios
      .put(`google-reviews-branches/${data.state}/${data.id} `)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setGoogleReviewStatus", data);
          commit("app/successMessage", data.state=='accepted'?"locationAccepted":"locationRejected", { root: true });

          
        }
      })
      
  }
};
