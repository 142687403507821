var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "drawerclass",
      staticStyle: { "max-height": "100%" },
      attrs: {
        id: "app-drawer",
        right: _vm.language.rtl == "rtl" ? true : false,
        app: "",
        dark: "",
        floating: "",
        persistent: "",
        "mobile-breakpoint": "992",
        width: "265",
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "fb-nav-list",
                  staticStyle: { padding: "15px 0" },
                },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: `/${_vm.$i18n.locale}/help-center` } },
                    [
                      _c(
                        "div",
                        { staticClass: "svg-icon" },
                        [
                          _c("info-icon", {
                            staticStyle: { height: "25px", width: "25px" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("helpCenter.title")),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          _vm.logoutPopup = true
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "svg-icon" },
                        [
                          _c("logout-icon", {
                            staticStyle: { height: "25px", width: "25px" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("Core.drawer.Logout")),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.inputValue,
        callback: function ($$v) {
          _vm.inputValue = $$v
        },
        expression: "inputValue",
      },
    },
    [
      _c(
        "v-list-item",
        { staticStyle: { padding: "9px 13px", height: "65px" } },
        [
          _vm.getIsLoggedIn
            ? _c("div", { staticClass: "layout align-center" }, [
                _c(
                  "a",
                  {
                    staticClass: "my-auto",
                    staticStyle: {
                      "background-color": "#fff",
                      "border-radius": "5px",
                      display: "flex",
                      padding: "4px",
                      width: "38px",
                      height: "38px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        alt: "Logo",
                        width: "30px",
                        src: "/media/logoIcon.svg",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { margin: "0px 7px" } }, [
                  _c(
                    "p",
                    {
                      staticClass: "subtitle my-0",
                      staticStyle: {
                        color: "#fff !important",
                        "line-height": "15px !important",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s("فيدباك - Feedback") +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "packageLabel",
                      staticStyle: { height: "14px !important" },
                    },
                    [_vm._v(_vm._s(_vm.$t("setting.mainUser")))]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.getIsLoggedIn
        ? _c(
            "div",
            {
              staticClass: "fb-nav-list",
              style: _vm.responsive
                ? " padding-top: 46px; "
                : "padding-top: 55px; ",
            },
            [
              _c(
                "v-list-item",
                { attrs: { to: `/${_vm.$i18n.locale}/dashboard` } },
                [
                  _c(
                    "div",
                    { staticClass: "svg-icon" },
                    [
                      _c("chart-icon", {
                        staticStyle: { height: "25px", width: "25px" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-title", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("Core.drawer.Dashboard")),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.navlinks, function (link, i) {
                return _c(
                  "v-list-item",
                  {
                    directives: [
                      {
                        name: "permissions",
                        rawName: "v-permissions",
                        value: link.permissions,
                        expression: "link.permissions",
                      },
                    ],
                    key: i,
                    attrs: { to: `/${_vm.$i18n.locale}/` + link.link },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "svg-icon" },
                      [
                        _c(link.icon, {
                          tag: "component",
                          staticStyle: { height: "25px", width: "25px" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-list-item-title", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("Core.drawer." + link.text)),
                      },
                    }),
                    _vm._v(" "),
                    link.label
                      ? _c("span", { staticClass: "menu-label" }, [
                          link.link == "ratings" &&
                          _vm.getStatistics.no_read_reviews > 0
                            ? _c("span", { staticClass: "NotificationLabel" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getStatistics.no_read_reviews > 99
                                      ? "+99"
                                      : _vm.getStatistics.no_read_reviews
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          link.link == "chats" &&
                          _vm.getStatistics.no_read_messages > 0
                            ? _c("span", { staticClass: "NotificationLabel" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getStatistics.no_read_messages > 99
                                      ? "+99"
                                      : _vm.getStatistics.no_read_messages
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._v(" "),
      _c("delete-popup", {
        attrs: {
          visible: _vm.logoutPopup,
          title: _vm.$t("dashboard.logout"),
          subTitle: _vm.$t("dashboard.logoutSubTitle"),
          btntitle: _vm.$t("dashboard.logout"),
          stop: 3,
        },
        on: {
          deleteAction: function ($event) {
            return _vm.logOutFunction()
          },
          close: function ($event) {
            _vm.logoutPopup = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }