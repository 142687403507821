export default {
  async loadAllPackages({ commit },data) {

    commit('app/loadingStart', null, { root: true });
    await this.$axios.get(`/packages`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setAllPackages', respo.data.data);
      }
    });
  }, 
  async loadOnePackage({ commit }, id) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`/packages/${id}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOnePackage', respo.data.data);
        }
      });
  },
 
  async editOnePackage({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/packages/${data.id}`, data)
      .then((respo) => {
        if (respo.status === 200) {
          this.$router.back();
        
        
          commit('app/successMessage', 'packageUpdated', { root: true });
        }
      });
  }, 
};
