export default {
//  upload file to backend and return uploaded data
    async uploadFiles({ commit }, data) {
    return await this.$axios
      .post(this.$config.baseURL + `api/v1/backend/file/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(respo => {
        if (respo.status === 200) {
          return respo.data.data;
        }
      })
      .catch(error => {
        throw error;
      });
  },
  // image uploader and  validation 
async uploadImageAction({ }, e) {
    let formData = new FormData();
    let imageFile = null
    let imageName = null
    let src = null
    let error = null
    if (e.target.files.length > 0) {
      var file = e.target.files[0];
      var types = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
      if (
        types.includes(
          e.target.files[0].name.substring(
            e.target.files[0].name.lastIndexOf('.') + 1,
            e.target.files[0].name.length
          )
        )
      ) {
        src=file
         
        formData.append('file', file);
        imageFile = file;
        error = null
        imageName = file.name;
      } else {
        error = 'notSupported';
      }
    }
    return {
      src: src,
      imageFile: imageFile,
      imageFormData: formData,
      imageName: imageName,
      error: error
    }
  },
}