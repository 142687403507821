

import Vue from 'vue'
let store;
export default function (context) {
  store = context.store;

};
// create directive to hide element if user has no permissions
function checkPermission(el, binding) {
  const { value } = binding// the permission
  const roles = store.state.auth.isLoggedIn && store.state.auth.isLoggedIn.permissions
  if (roles) {
    if (value && value instanceof Array) {
      if (value.length > 0) {
        const permissionRoles = value
        const hasPermission = roles.some(role => {
          return permissionRoles.includes(role)
        })

        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      }
    } else {
      throw new Error(`need roles! Like v-permission="['admin','editor']"`)
    }
  }

}

Vue.directive('permissions', {
  inserted: (el, binding) => {
    checkPermission(el, binding)
  },
  update: (el, binding) => {
    checkPermission(el, binding)
  }
})