//import i18n from '~/plugins/i18n'
import Vue from "vue";
export default {
  setAllGoogleReviewRequestes: (state, data) => {
    state.googleReviewBranches = data.data.content;
    state.finishDialog = false;
  },
  // delete google review branch from list
  setGoogleReviewStatus: (state, data) => {
    var index = state.googleReviewBranches.findIndex(
      (item) => item.id == data.id
    );
    if (index > -1) {
      state.googleReviewBranches.splice(index, 1);
    }

  }
};
