
export default {

    async LoadAllMessages({ commit, dispatch }, data) {
        commit('app/loadingStart', null, { root: true })

        await this.$axios
            .get(`all-messages`, null)
            .then((respo) => {
                if (respo.status === 200) {
                    commit('setAllMessages', respo.data.data.content)
                    dispatch('LoadStoreMessages', respo.data.data.content[0].store_id)

                }
            })
    },
    async LoadStoreMessages({ commit }, data) {
        commit('app/loadingStart', null, { root: true })

        await this.$axios
            .get(`private-message/${data}`, null)
            .then((respo) => {
                if (respo.status === 200) {
                    commit('setStoreMessages', respo.data.data.content)

                }
            })
    },
    async sendOneMessage({ commit, dispatch }, data) {
        commit('app/loadingStart', null, { root: true })
        let formData = new FormData();
        formData.append("store_id", data.store_id);
        formData.append("message", data.newMessage);
        formData.append("file", data.FileToUpload ? data.FileToUpload : '');

        await this.$axios
            .post(`send-private-message`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((respo) => {
                if (respo.status === 200) { 
                }
            })
    },
}