//import i18n from '~/plugins/i18n'
import Vue from "vue";
export default {
  setAllStores: (state, data) => {
    state.allStores = data.data;
  },
  setOneStore: (state, data) => {
    state.oneStore = data.content;
  },
  // update the user's state property with the data.state value
  setChangeStoreState: (state, data) => {
    if (state.allStores && state.allStores.content)
      var index = state.allStores.content.findIndex((s) => s.id == data.id);
    if (index > -1) {
      state.allStores.content[index].state = data.state;
    }
    if (state.oneStore) {
      state.oneStore.state = data.state;
    }
  },
  // remove deleted storre from the list
  deleteStore: (state, data) => {
    if (state.allStores && state.allStores.content)
      var index = state.allStores.content.findIndex((s) => s.id == data);
    if (index > -1) {
      state.allStores.content.splice(index, 1);
    }
    state.oneStore = null;
  },
};
