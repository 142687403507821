export default {
  async loadAllUsers({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(`get-users?page=${data.page}&per_page=${data.per_page}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAllUsers", respo.data);

          
        }
      })
      
  },
  async loadOneUser({ commit }, userId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(`get-users/${userId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setOneUser", respo.data.data);

          
        }
      })
      
  },
  async changeUserStateActivate({ commit }, userId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .put(`user-state-change/${userId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setChangeUserState", { id: userId, state: "1" });
          commit("app/successMessage", "userActivated", { root: true });

          
        }
      })
      
  },
  async changeUserStateDisable({ commit }, userId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .put(`user-state-change/${userId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setChangeUserState", { id: userId, state: "2" });
          commit("app/successMessage", "userDisabled", { root: true });

          
        }
      })
      
  },
  async deleteOneUser({ commit }, userId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .delete(`delete-user/${userId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("deleteUser", userId);
          commit("app/successMessage", "userDeleted", { root: true });

          
        }
      })
      
  }, 
};
