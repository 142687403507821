var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { position: "relative" },
      style: _vm.responsive
        ? ""
        : this.$i18n.locale == "ar"
        ? "margin-top:117px;margin-right:266px"
        : "margin-top:117px;margin-left:266px",
      attrs: { id: "core-view " },
    },
    [
      _c(
        "v-layout",
        {
          staticClass: "align-center",
          staticStyle: { margin: "2px 20px" },
          attrs: { "justify-space-between": "" },
        },
        [
          _vm.responsive
            ? _c(
                "v-flex",
                { attrs: { lg9: "", md9: "", sm9: "", xs12: "" } },
                [
                  _c(
                    "v-autocomplete",
                    {
                      staticClass: "searchBar2",
                      attrs: {
                        items: _vm.items,
                        loading: false,
                        placeholder: _vm.$t("Core.Header.Search") + " ...",
                        "search-input": _vm.search,
                        "hide-selected": "",
                        solo: "",
                        "hide-no-data": "",
                        "hide-details": "auto",
                        "item-text": "name",
                        "item-value": "abbr",
                        "return-object": "",
                        "append-icon": "",
                      },
                      on: {
                        "update:searchInput": function ($event) {
                          _vm.search = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.search = $event
                        },
                      },
                      model: {
                        value: _vm.model,
                        callback: function ($$v) {
                          _vm.model = $$v
                        },
                        expression: "model",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { slot: "prepend-inner" },
                          slot: "prepend-inner",
                        },
                        [_c("search-icon")],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-fade-transition", { attrs: { mode: "out-in" } }, [_c("nuxt")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }