export default {
  getAllUsers(state) {
    return state.allUsers
  },
  getOneUser(state) {
    return state.oneUser
  },
  getOneCampaign(state) {
    return state.oneCampaign
  },
  getEnableDialog(state) {
    return state.enableDialog
  },
  getDisableDialog(state) {
    return state.disableDialog
  },
  getDeleteDialog(state) {
    return state.deleteDialog
  },

}
