export default {
  async loadOneBranch({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(`/branches/${data.store}/${data.branch}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          
          commit("setOneBranch", respo.data.data);
        }
      })
      
  },
  // loading qr code as blob 
  async loadQrCode({ commit }, data) {
    commit("app/loadingStart", null, { root: true });
   
    return await this.$axios
      .get(
        this.$config.baseURL +`api/v1/backend/download-qr-code/${data.id}?type=${data.type}`,
        {
          responseType: "blob",
        }
      )
      .then(response => {
        var blob = response.data;
        return blob;

     
      });

      
  },
  // This code snippet is an asynchronous function in JavaScript that uses the html2canvas library to generate different types of image files (SVG, PDF, DXF, or PNG) based on the input data. The generated image files are then downloaded or displayed on the web page. 
  async getHtmlCanvas({ commit }, data) {
    await data.html2canvas(data.element, {
      type: 'dataURL',
      allowTaint: false,
      useCORS: true,
      logging: true,
      scale: data.fileType == 'DXF' ? 0.5 : 5,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById('qrShow').style.display = 'block';
      },
    }).then((canvas) => {
      var link = document.createElement('a');
      if (data.fileType == 'SVG') {
        var ctx = new data.C2S(canvas.width, canvas.height);
        let width = 720
        let height = (canvas.height / canvas.width) * width
        ctx.drawImage(canvas, 10, 10, width, height);
        var mySvg = ctx.getSerializedSvg(true);
        link.download = data.title + '_qr.svg';
        link.href = 'data:image/svg+xml;utf8,' + mySvg;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        commit('app/loadingFinish', null, { root: true });
      }
      else if (data.fileType == 'PDF') {

        var myImage = canvas.toDataURL('image/png');

        const pdf = new data.jsPDF();
        const aspectRatio = canvas.width / canvas.height;

        let maxHeight = 290
        let width = 210

        let height = width / aspectRatio;

        if (height > maxHeight) {
          height = maxHeight;
          width = height * aspectRatio;
        }
        let marginStart = (210 - width) / 2

        // let height = ((canvas.height/ canvas.width) * width) 
        pdf.addImage(myImage, "png", marginStart, 0, width, height);
        pdf.save(data.title + '_qr.PDF');
        commit('app/loadingFinish', null, { root: true });
      }
      else if (data.fileType == 'DXF') {
        const writer = new DxfWriter();

        const context = canvas.getContext('2d');
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
     
        let colors = [] 
        const solid = pattern({
          name: HatchPredefinedPatterns.SOLID
        });
        for (let y = 0; y < canvas.height; y++) {
          for (let x = 0; x < canvas.width; x++) {
            const index = (y * canvas.width + x) * 4; 
            let color = imageData.data[index]
            if (imageData.data[index] > 208 && imageData.data[index] < 254) {
              color = 254
            }
            if (imageData.data[index] > 190 && imageData.data[index] < 209) {
              color = 255
            }
            if (imageData.data[index] > 130 && imageData.data[index] < 191) {
              color = 192
            }
            if (imageData.data[index] > 30 && imageData.data[index] < 59) {
              color = 192
            }
            if (imageData.data[index] > 70 && imageData.data[index] < 131) {
              color = 192
            }
            if (imageData.data[index] > 58 && imageData.data[index] < 71) {
              color = 192
            }
            if (color != 255) {
              let polyline = new HatchPolylineBoundary();
              polyline.add(vertex(x, (canvas.height - 1 - y)));
              polyline.add(vertex(x, (canvas.height - 1 - y) + 1));
              polyline.add(vertex(x + 1, (canvas.height - 1 - y) + 1));
              polyline.add(vertex(x + 1, (canvas.height - 1 - y)));
              let boundary = new HatchBoundaryPaths();

              boundary.addPolylineBoundary(polyline);

              const vertices = [
                {
                  point: point2d(x, (canvas.height - 1 - y)),
                },
                {
                  point: point2d(x, (canvas.height - 1 - y) + 1),
                },
                {
                  point: point2d(x + 1, (canvas.height - 1 - y) + 1),
                },
                {
                  point: point2d(x + 1, (canvas.height - 1 - y)),
                },
              ];

              if (colors.includes(color)) {

                writer
                  .layer('qr' + color)
                writer
                  .addLWPolyline(vertices, {
                    flags: LWPolylineFlags.Closed,
                  });
                writer.addHatch(boundary, solid, {
                  colorNumber: + color,
                  layerName: 'qr' + color,
                });

              } else {
                colors.push(color)

                writer
                  .addLayer('l_yellow' + color, color, 'CONTINUOUS')
                writer
                  .layer('l_yellow' + color)
                writer
                  .addLWPolyline(vertices, {
                    flags: LWPolylineFlags.Closed,
                  });
                writer.addHatch(boundary, solid, {
                  colorNumber: 3,
                  layerName: 'l_yellow' + color,
                });

              }

            }

          }
        }

        const blob = new Blob([writer.stringify()], { type: 'application/dxf' });
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = data.title + '_qr.dxf';
        link.click();

        commit('app/loadingFinish', null, { root: true });
      }
      else {

        var myImage = canvas.toDataURL('image/png');
        link.download = data.title + '_qr.png';
        link.href = myImage;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        commit('app/loadingFinish', null, { root: true });


      }
    });
  }
};
