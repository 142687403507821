<template>
  <v-navigation-drawer id="app-drawer" :right="language.rtl == 'rtl' ? true : false" app dark v-model="inputValue"
    floating class="drawerclass" persistent mobile-breakpoint="992" width="265" style="max-height: 100%;">
    <v-list-item style="padding: 9px 13px; height: 65px">
      <div v-if="getIsLoggedIn" class="layout align-center">
        <a class="my-auto"
          style="background-color:#fff;border-radius:5px;display:flex; padding:4px; width:38px;  height:38px; ">
          <img alt="Logo" width="30px" src="/media/logoIcon.svg" />
        </a>
        <div style="margin: 0px 7px">
          <p class="subtitle my-0" style="color: #fff !important; line-height: 15px !important">
            {{ 'فيدباك - Feedback' }}
          </p>
          <span class="packageLabel" style="height: 14px !important">{{ $t('setting.mainUser') }}</span>

        </div>
      </div>
    </v-list-item>

    <div v-if="getIsLoggedIn" class="fb-nav-list" :style="responsive ? ' padding-top: 46px; ' : 'padding-top: 55px; '">
      <v-list-item :to="`/${$i18n.locale}/dashboard`">
        <div class="svg-icon">
          <chart-icon style="height: 25px; width: 25px" />
        </div> 

        <v-list-item-title v-text="$t('Core.drawer.Dashboard')" />
      </v-list-item>
      <v-list-item v-for="(link, i) in navlinks" :key="i" :to="`/${$i18n.locale}/` + link.link"
        v-permissions="link.permissions">
        <div class="svg-icon">
          <component :is="link.icon"  style="height: 25px; width: 25px" />
        </div>

        <v-list-item-title v-text="$t('Core.drawer.' + link.text)" />
        <span class="menu-label" v-if="link.label">
          <span v-if="link.link == 'ratings' && getStatistics.no_read_reviews > 0"
            class="  NotificationLabel">{{ getStatistics.no_read_reviews > 99 ? '+99' : getStatistics.no_read_reviews }}</span>
          <span v-if="link.link == 'chats' && getStatistics.no_read_messages > 0"
            class="  NotificationLabel">{{ getStatistics.no_read_messages > 99 ? '+99' : getStatistics.no_read_messages }}</span>
          
        </span>
      </v-list-item>
    </div>
    <template v-slot:append>
      <div class="fb-nav-list" style="padding: 15px 0">
        <v-list-item :to="`/${$i18n.locale}/help-center`">
          <div class="svg-icon">
            <info-icon style="height: 25px; width: 25px" />
          </div>

          <v-list-item-title v-text="$t('helpCenter.title')" />
        </v-list-item>
        <v-list-item @click="logoutPopup = true">
          <div class="svg-icon">
            <logout-icon style="height: 25px; width: 25px" />
          </div>

          <v-list-item-title v-text="$t('Core.drawer.Logout')" />
        </v-list-item>
      </div>
    </template>
    <delete-popup @deleteAction="logOutFunction()" :visible="logoutPopup" :title="$t('dashboard.logout')"
      :subTitle="$t('dashboard.logoutSubTitle')" :btntitle="$t('dashboard.logout')" @close="logoutPopup = false"
      :stop="3" />
  </v-navigation-drawer>
</template>

<script>
// Utilities

import { permissions as permissionsEnum } from "@/static/js/permissions.enum";

import deletePopup from "../general/feedbacks/deletePopup.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: { deletePopup },
  props: {
    opened: {
      type: Boolean,
      default: true,
    },
  }, 
  data: () => ({
    permissions: permissionsEnum,
    logoutPopup: false,
    mini: false, 
    navlinks: [
      {
        icon: 'transactions-icon',
        text: "requestes",
        link: "requestes",
        label: true,
        permissions: [permissionsEnum.superReadTransactions]
      },
      {
        icon: 'user-icon',
        text: "users",
        link: "users",
        permissions: [permissionsEnum.superReadUsers]
      },
      {
        icon: "branches-icon",
        text: "advertisers",
        link: "advertisers",
        permissions: [permissionsEnum.superReadAdvertisers]
      }, 
      {
        icon: 'rating-questions-icon',
        text: "questions_bank",
        link: "questions-bank",
        permissions: [permissionsEnum.superReadQuestions]
      },
      {
        icon: 'subscriptions-icon',
        text: "surveys",
        link: "surveys",
        permissions: [permissionsEnum.superReadAdvertisers]
      },
      {
        icon: 'subscriptions-icon',
        text: "subscriptions",
        link: "subscriptions",
        permissions: [permissionsEnum.superReadAdvertisers]
      },
      {
        icon: 'coupon-icon',
        text: "coupons",
        link: "coupons",
        permissions: [permissionsEnum.superReadCoupons]
      },
      {
        icon: 'ratings-icon',
        text: "Ratings",
        link: "ratings",
        label: true,
        permissions: [permissionsEnum.superReadReviews]
      },
      {
        icon: 'ads-icon',
        text: "advertisements",
        link: "advertisements",
        permissions: [permissionsEnum.superReadAdvertisements]
      },
      {
        icon: 'chat-icon',
        text: "Chats",
        link: "chats",
        label: true,
        permissions: [permissionsEnum.superReadChats]
      },
      {
        icon: 'customer-service-icon',
        text: "customer_service",
        link: "customer-service",
        label: true,
        permissions: [permissionsEnum.superReadCustomerService]
      },
      {
        icon: 'settings-icon',
        text: "Settings",
        link: "settings",
        permissions: [permissionsEnum.superReadReports, permissionsEnum.superReadTypeOfBusiness, permissionsEnum.superReadSystemUsers]
      },
    ],
  }),
  computed: {
    ...mapGetters({
      language: "app/getlang",
      getIsLoggedIn: "auth/getIsLoggedIn",
      getStatistics: "reports/getStatistics",
    }),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
    responsive() {
      if (
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "xs"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.loadUserDetail();
    this.loadDasboardStatisitcs();
  },
  methods: {
    ...mapActions({
      loadUserDetail: "auth/loadUserDetail",
      logoutFromStore: "auth/logout",
      loadDasboardStatisitcs: "reports/loadDasboardStatisitcs",
    }),
    ...mapMutations({
      setDrawer: "app/setDrawer",
    }),
    logOutFunction() {
      this.logoutPopup = true;
      this.logoutFromStore();
    },
  },
};
</script>


<style lang="scss">
#app-drawer {
  background-color: #1e1e2d;
}
</style>
