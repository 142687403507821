export default {
   
  getAllQuestions(state) {
    return state.allQuestions;
  },
  getOneQuestion(state) {
    return state.oneQuestion;
  },
  getAddDialog(state) {
    return state.addDialog;
  },
  getUpdateDialog(state) {
    return state.updateDialog;
  },
  getDeleteDialog(state) {
    return state.deleteDialog;
  },
};
