<template>
  <div :class="'textboxContainer ' + classes">
    <v-layout v-if="label != null" justify-space-between align-center>
      <label
        :class="
          packageDisabled ? '   form__label diabled_field' : '   form__label'
        "
        >{{ label + ' ' }}<span v-if="required" class="text-danger">*</span
        ><span
          v-if="subLabel !== null"
          class="text-regular-14 text-font-secondary"
          style="margin-right: 4px; margin-left: 4px; line-height: 12px"
          >{{ subLabel }}</span
        ></label
      >
      <v-spacer />
      <slot name="link" />
    </v-layout>

    <v-text-field
      v-bind="$attrs"
      solo
      :disabled="disabled"
      @input="updateValue($event)" 
      :placeholder="placeholder"
      :append-icon="appendicon"
      :value="value"
      :rules="typeofrules"
      hide-details="auto"
      @click:append="append"
      :type="type"
    >
      <template #append>
        <slot name="icon" />
      </template>
      <template #prepend-inner>
        <slot name="icon2" />
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  inheritsAttrs: false,
  name: 'TextBoxMaterial',
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    appendicon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'blue',
    },
    label: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: '',
    },
    value: {
      type: [String,Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    textBox: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: ' ',
    },
    inputClass: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    packageDisabled: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },
    passwordValid: {
      type: Boolean,
      default: false,
    },
    subLabel: {
      type: String,
      default: null,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isText: false,
  }),
  computed: {
    // validations
    typeofrules() {
      if (this.rules === "usernameRules") {
        return [
          (v) =>
            (v && v.length <= 80) || this.$t("validation.exceededlimitation"),
        ];
      }
      if (this.rules === 'percentageRule') {
        return [
        (v) => !!v || this.$t("validation.emptyfieldrequired"),
          (v) =>
            (v && v > -1 && v <= 100) || this.$t('validation.exceededPrecentagelimitation'),
        ];
      }
      if (this.rules === 'increaseRule') {
        return [
        (v) => !!v || this.$t("validation.emptyfieldrequired"),
          (v) =>
            (v && v > 0 && v <= 50) || this.$t('validation.exceededIncreaselimitation'),
        ];
      }
      if (this.rules === "titleRules") {
        return [
          (v) =>
            (v && v.length <= 15) || this.$t("validation.exceededlimitation"),
        ];
      }
      if (this.rules === "subTitleRules") {
        return [
          (v) =>
            (v && v.length <= 30) || this.$t("validation.exceededlimitation"),
        ];
      }
      if (this.rules === "requiredRules") {
        return [(v) => !!v || this.$t("validation.emptyfieldrequired")];
      }
      if (this.rules === "lastNameRules") {
        return [
          (v) =>
            (v && v.length <= 80) || this.$t("validation.exceededlimitation"),
        ];
      }
      if (this.rules === "usermailRules") {
        return [
          (v) => /.+@.+\..+/.test(v) || this.$t("validation.emailvalidation"),
          (v) =>
            (v && v.length <= 100) || this.$t("validation.exceededlimitation"),
        ];
      }
      if (this.rules === "onlynumber") {
        return [(v) => /^[0-9]+$/.test(v) || this.$t("validation.number")];
      }
      if (this.rules === "pointsValidation") {
        return [
          (v) => /^[0-9]+$/.test(v) || this.$t("validation.number"),
          (v) => (v && v >= 1000) || this.$t("validation.minpointValidation"),
        ];
      }

      if (this.rules === "phoneRules" && this.isMobile) {
        return [
          (v) =>
            v == null ||
            v === "" ||
            /^[0-9]+$/.test(
              v
            ) ||
            this.$t("validation.phonevalidation"),
          (v) =>
            v == null ||
            v === "" ||
            (v && v.length >= 9) ||
            this.$t("validation.undermainlimitationNumber2"),
          (v) =>
            v == null ||
            v === "" ||
            (v && v.length <= 15) ||
            this.$t("validation.exceededlimitation"),
        ];
      }
         if (this.rules === "phoneRules" && this.isMobile==false) {
        return [
          (v) =>
            v == null ||
            v === "" ||
            /^[0-9]+$/.test(
              v
            ) ||
            this.$t("validation.phonevalidation"),
          (v) =>
            v == null ||
            v === "" ||
            (v && v.length >= 6) ||
            this.$t("validation.undermainlimitationNumber"),
          (v) =>
            v == null ||
            v === "" ||
            (v && v.length <= 15) ||
            this.$t("validation.exceededlimitation"),
        ];
      }
      if (this.rules === "phoneRules2") {
        return [
          (v) => /^[0-9]+$/.test(v) || this.$t("validation.number"),
          (v) =>
            (v && v.length >= 6) ||
            this.$t("validation.undermainlimitationNumber"),
          (v) =>
            (v && v.length <= 15) || this.$t("validation.exceededlimitation"),
        ];
      }
      if (this.rules === "urlRule") {
        var pattern = new RegExp(
          "^(https?:\\/\\/)" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        ); // fragment locator

        return [
          (v) =>
            v == null ||
            v === "" ||
            pattern.test(v) ||
            this.$t("validation.Url"),
        ];
      }
      if (this.rules === "urlRul2") {
        var pattern = new RegExp(
          "^(https?:\\/\\/)" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        ); // fragment locator

        return [
          (v) =>
        (  !!v &&
            pattern.test(v) )||
            this.$t("validation.Url"),
        ];
      }

      if (this.rules === "youtubUrlRule") {
        var pattern = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/; 

        return [
          (v) =>
        (  !!v &&
            pattern.test(v) )||
            this.$t("validation.Url"),
        ];
      }

      if (this.rules === "pwdRules") {
        return [
          (v) => {
            const pattern =
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/;
            return pattern.test(v) || this.$t("validation.PasswordValidation");
          },
          (v) =>
            (v && v.length <= 250) || this.$t("validation.exceededlimitation"),
        ];
      }
      if (this.rules === "notmuch") {
        return [this.$t("validation.passwordnotmuch")];
      }
      if (this.rules === "campaignPoints") {
        return [(v) => /^[0-9]+$/.test(v) || this.$t("validation.number"),
        this.$t("validation.maxPointValidation")];
      }
      
      if (this.rules === "pointsPerPerson") {
        return [(v) => /^[0-9]+$/.test(v) || this.$t("validation.number"),this.$t("validation.pointPerPersonValidation")];
      }
    
      if (this.required) {
        return [(v) => !!v || this.$t("validation.emptyfieldrequired")];
      }
      // else{
      //      return  v => (v && v !== this.rules) || this.$t('validation.exceededlimitation')
      // }
      
    },
  },
  methods: {
    append() {
      this.$emit('append');
    }, 
    updateValue(value) {
      var arabicNumbers = [
        /٠/g,
        /١/g,
        /٢/g,
        /٣/g,
        /٤/g,
        /٥/g,
        /٦/g,
        /٧/g,
        /٨/g,
        /٩/g,
      ];
      var persianNumbers = [
        /۰/g,
        /۱/g,
        /۲/g,
        /۳/g,
        /۴/g,
        /۵/g,
        /۶/g,
        /۷/g,
        /۸/g,
        /۹/g,
      ];
      // replace any number to english numbers
      if (typeof value === 'string') {
        for (var i = 0; i < 10; i++) {
          value = value
            .replace(persianNumbers[i], i)
            .replace(arabicNumbers[i], i);
        }
      }
    
      
      // return str;
      this.$emit('input', value);
    }, 
  },
};
</script>