export default {
  async loadAllCategories({ commit },data) {
    commit('setAllCategories',{content:null});

    commit('app/loadingStart', null, { root: true });
    await this.$axios.get(`stores/categories?type=${data.type}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setAllCategories', respo.data.data);
      }
    });
  }, 
  async loadOneCategory({ commit }, categoryId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`/stores/categories/${categoryId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneCategory', respo.data.data);
        }
      });
  },
  async saveNewCategory({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`/stores/categories`, data).then((respo) => {
      if (respo.status === 200) {
      
          commit('InsertCategory', {
            ...respo.data.data.content
          });
      
        commit('app/successMessage', 'categoryAdded', { root: true });
      }
    });
  },
  async editOneCategory({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/stores/categories/${data.categoryId}`, data.categoryDetails)
      .then((respo) => {
        if (respo.status === 200) {
       
            commit('UpdateCategory', {
              ...respo.data.data.content
            });
        
          commit('app/successMessage', 'categoryUpdated', { root: true });
        }
      });
  },
  async deleteOneCategory({ commit }, categoryId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .delete(`/stores/categories/${categoryId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
         
          commit('deleteCategory', categoryId);
         if(respo.data.data.content)
          commit('UpdateCategory', {
            ...respo.data.data.content
          });
          commit('app/successMessage', 'categoryDeleted', { root: true });
        }
      });
  },

  // category surveys
  async saveNewSurvey({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`/surveys`, data).then((respo) => {
      if (respo.status === 200) {
      
          commit('InsertSurvey', {
            ...respo.data.data.content
          });
      
        commit('app/successMessage', 'surveyAdded', { root: true });
      }
    });
  },
  async editOneSurvey({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/surveys/${data.id}`, data)
      .then((respo) => {
        if (respo.status === 200) {
       
            commit('UpdateSurvey', {
              ...respo.data.data.content
            });
        
          commit('app/successMessage', 'surveyUpdated', { root: true });
        }
      });
  },
  async deleteOnSurvey({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .delete(`/surveys/${data.id}`, null)
      .then((respo) => {
        if (respo.status === 200) {
         
          commit('deleteSurvey', data);
          commit('app/successMessage', 'surveyDeleted', { root: true });
        }
      });
  },
  async reorderSurveys({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .post(`/surveys/order`, data)
      .then((respo) => {
        if (respo.status === 200) {
         
         
          commit('UpdateCategory', {
            ...respo.data.data.content
          });

          commit('deleteSureyFromCategory',data);
          data.type=='reorder'?   commit('app/successMessage', 'reorderDone', { root: true }):commit('app/successMessage', 'moveDone', { root: true });
       
        }
      });
  },
  async changeSurveyState({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/surveys/state/${data.id}`, null)
      .then(respo => {
        if (respo.status === 200) {
          commit('setSurveyState', data);
          commit('app/successMessage', 'surveyUpdated', { root: true });
         
          }
           
      });
  },
};
