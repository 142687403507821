import Vue from 'vue';
import LeftIcon from '~/static/media/icon/leftIcon.svg?inline';
import RightIcon from '~/static/media/icon/rightIcon.svg?inline';
import EditIcon from '~/static/media/icon/editIcon.svg?inline';
import CloseIcon from '~/static/media/icon/closeIcon.svg?inline';
import user from '~/static/media/icon/user.svg?inline';
import charts from '~/static/media/icon/charts.svg?inline';
import diamond from '~/static/media/icon/diamond.svg?inline';
import advanceSettings from '~/static/media/icon/advanceSettings.svg?inline';
import info from '~/static/media/icon/Info.svg?inline';
import Sections from '~/static/media/icon/Sections.svg?inline';
import plusIcon from '~/static/media/icon/plusIcon.svg?inline';
import download from "~/static/media/icon/download.svg?inline";
import filterIcon from "~/static/media/icon/filter.svg?inline";
import searchIcon from "~/static/media/icon/Search.svg?inline";
import uploadImage from '~/static/media/icon/uploadImg.svg?inline';
import uploadImages from '~/static/media/icon/uploadimage.svg?inline';
import questionRating from '~/static/media/icon/questionRating.svg?inline';
import campaign from '~/static/media/icon/campaign.svg?inline';
import campaign2 from '~/static/media/icon/campaignIcon.svg?inline';
import starRate from '~/static/media/icon/starRate.svg?inline';
import arSvg from "~/static/media/icon/ar.svg?inline";
import enSvg from "~/static/media/icon/en.svg?inline";
import viewDetails from '~/static/media/icon/viewDetails.svg?inline';
import verticaldots from '~/static/media/icon/Verticaldots.svg?inline';
import pocket1 from '~/static/media/icon/pocket1.svg?inline';
import pocket2 from '~/static/media/icon/pocket2.svg?inline';
import passwordChange from '~/static/media/icon/changePassword.svg?inline';
import clock from '~/static/media/icon/clock.svg?inline';
import transactions from '~/static/media/icon/transaction.svg?inline';
import noData from '~/static/media/icon/noData.svg?inline';
import branches from '~/static/media/icon/branches.svg?inline';
import eyeon from '~/static/media/icon/eyeon.svg?inline';
import eyeoff from '~/static/media/icon/eyeoff.svg?inline';
import countinu from '~/static/media/icon/countinu.svg?inline';
import barcode from '~/static/media/icon/barcode.svg?inline';

import notifications from '~/static/media/icon/Notifications.svg?inline';
import deleteSVG from '~/static/media/icon/delete.svg?inline';
import ratings from '~/static/media/icon/ratings.svg?inline'; 
import textIcon from '~/static/media/icon/text.svg?inline'; 
import storeIcon from '~/static/media/icon/store.svg?inline'; 
import stopIcon from '~/static/media/icon/stop.svg?inline'; 
import couponIcon from '~/static/media/icon/coupon.svg?inline'; 
import copy from '~/static/media/icon/copy.svg?inline';
import surveysIcon from '~/static/media/icon/surveys.svg?inline'; 


import faceIcon from '~/static/media/icon/face.svg?inline'; 
import dateIcon from '~/static/media/icon/date.svg?inline'; 
import openLinkIcon from '~/static/media/icon/open-link.svg?inline'; 
import playerIcon from '~/static/media/icon/player.svg?inline'; 


import nps from "~/static/media/icon/nps-icon.svg?inline";
import multipleAnswers from "~/static/media/icon/multiple-answers.svg?inline";

import dragmenuIcon from '~/static/media/icon/dragmenu.svg?inline'; 

import pdfIcon from '~/static/media/icon/pdf.svg?inline';

import CheckIcon from '~/static/media/icon/check-icon.svg?inline'; 
import adsIcon from '~/static/media/icon/ads.svg?inline'; 


import rolesIcon from '~/static/media/icon/roles-icon.svg?inline'; 
import packageIcon from "~/static/media/icon/packageIcon.svg?inline";

import performance from '~/static/media/icon/performance.svg?inline';
import uploadIcon from '~/static/media/icon/upload.svg?inline';
import sliderIcon from '~/static/media/icon/slider.svg?inline';
import toggleIcon from '~/static/media/icon/toggle.svg?inline';


import document from '~/static/media/icon/document.svg?inline';
import statistics from '~/static/media/icon/statistics.svg?inline';
import check from '~/static/media/icon/check.svg?inline';
import usersGroup from '~/static/media/icon/users-group.svg?inline';
import rocket from '~/static/media/icon/rocket.svg?inline';
import planet from '~/static/media/icon/planet.svg?inline';
import fire from '~/static/media/icon/fire.svg?inline';
import exportIcon from '~/static/media/icon/export.svg?inline';

import happyIcon from '~/static/media/icon/happy.svg?inline'; 
import smileyIcon from '~/static/media/icon/smiley.svg?inline'; 
import okIcon from '~/static/media/icon/ok.svg?inline'; 
import sadIcon from '~/static/media/icon/sad.svg?inline'; 
import angryIcon from '~/static/media/icon/angry.svg?inline'; 
import uploadFileIcon from '~/static/media/icon/uploadFileIcon.svg?inline';
import voiceIcon from '~/static/media/icon/voice.svg?inline';

import CSChat from '~/static/media/icon/CSChat.svg?inline';
import prevIcon from '~/static/media/icon/prev-icon.svg?inline';
import Chat from "~/static/media/icon/Chat.svg?inline";

import chart from "~/static/media/icon/chart.svg?inline";
import subscriptions from "~/static/media/icon/subscriptions.svg?inline";
import ratingQuestions from "~/static/media/icon/ratingQuestions.svg?inline";
import settings from "~/static/media/icon/settings.svg?inline";
import logout from "~/static/media/icon/logout.svg?inline";
import customerService from "~/static/media/icon/customer-service.svg?inline";
import google from "~/static/media/icon/google.svg?inline";
import noDataIcon from '~/static/media/icon/nodata-icon.svg?inline';
import cleanIcon from '~/static/media/icon/cleanIcon.svg?inline';
 
import less from "~/static/media/icon/less.svg?inline";
import moreLess from "~/static/media/icon/moreLess.svg?inline"; 
import minusIcon from '~/static/media/icon/minusIcon.svg?inline';
import activateIcon from "~/static/media/icon/activateIcon.svg?inline";
import report from "~/static/media/icon/report.svg?inline";

Vue.component('rating-questions-icon',ratingQuestions);
Vue.component('chart-icon',chart);
Vue.component('CSChat-icon',CSChat);
Vue.component('chat-icon',Chat);
Vue.component('prev-icon',prevIcon);
Vue.component('logout-icon',logout);
Vue.component('customer-service-icon',customerService);
Vue.component('subscriptions-icon',subscriptions);
Vue.component('settings-icon',settings);
Vue.component('google-icon',google);
Vue.component('clear-icon',cleanIcon);
Vue.component('minus-icon',minusIcon);
Vue.component('report-icon',report);

Vue.component('nodata-icon',noDataIcon);
 
Vue.component('more-icon',moreLess);
Vue.component('less-icon',less);
Vue.component('activate-icon',activateIcon);

Vue.component('performance-icon',performance);
Vue.component('upload-icon',uploadIcon);
Vue.component('slider-icon',sliderIcon);
Vue.component('toggle-icon',toggleIcon);
Vue.component('voice-icon',voiceIcon);

Vue.component('document-icon',document);
Vue.component('statistics-icon',statistics);
Vue.component('check-answers-icon',check);
Vue.component('users-group-icon',usersGroup);
Vue.component('rocket-icon',rocket);
Vue.component('planet-icon',planet);
Vue.component('fire-icon',fire);
Vue.component('export-icon',exportIcon);

Vue.component('close-icon',CloseIcon);
Vue.component('left-icon',LeftIcon);
Vue.component('right-icon',RightIcon);
Vue.component('edit-icon',EditIcon);
Vue.component('user-icon',user);
Vue.component('charts-icon',charts);
Vue.component('diamond-icon',diamond);
Vue.component('advance-settings-icon',advanceSettings);
Vue.component('plus-icon',plusIcon);
Vue.component('sections-icon',Sections);
Vue.component('info-icon',info);
Vue.component('download-icon',download);
Vue.component('filter-icon',filterIcon);
Vue.component('search-icon',searchIcon);
Vue.component('upload-image-icon',uploadImage);
Vue.component('question-rating',questionRating);
Vue.component('star-icon',starRate);
Vue.component('campaign-icon',campaign);
Vue.component('campaign2-icon',campaign2);
Vue.component('ar-svg-icon',arSvg);
Vue.component('en-svg-icon',enSvg);
Vue.component('view-details-icon',viewDetails);
Vue.component('vertical-dots-icon',verticaldots);
Vue.component('pocket1-icon',pocket1);
Vue.component('pocket2-icon',pocket2);
Vue.component('password-change-icon',passwordChange);
Vue.component('clock-icon',clock);
Vue.component('transactions-icon',transactions);
Vue.component('no-data-icon',noData);
Vue.component('branches-icon',branches);
Vue.component('eyeon-icon',eyeon);
Vue.component('eyeoff-icon',eyeoff);
Vue.component('countinu-icon',countinu);
Vue.component('barcode-icon',barcode);
Vue.component('notifications-icon',notifications);
Vue.component('delete-icon',deleteSVG);
Vue.component('upload-images-icon',uploadImages );
Vue.component('ratings-icon',ratings );
Vue.component('text-icon',textIcon );
Vue.component('store-icon',storeIcon );
Vue.component('stop-icon',stopIcon );
Vue.component('coupon-icon',couponIcon );

Vue.component('copy-icon',copy);
Vue.component('surveys-icon',surveysIcon );

Vue.component('csat-icon',faceIcon );
Vue.component('smiley_faces-icon',faceIcon );

Vue.component('calender-icon',dateIcon );



Vue.component('happy-icon',happyIcon );
Vue.component('smiley-icon',smileyIcon );
Vue.component('ok-icon',okIcon );
Vue.component('sad-icon',sadIcon );
Vue.component('angry-icon',angryIcon );


Vue.component('open-link-icon',openLinkIcon );
Vue.component('player-icon',playerIcon );

Vue.component('nps-icon',nps);
Vue.component('choices-icon',multipleAnswers);

Vue.component('dragmenu-icon',dragmenuIcon );
Vue.component('pdf-icon',pdfIcon);
Vue.component('check-icon',CheckIcon);
Vue.component('ads-icon',adsIcon);
Vue.component('roles-icon',rolesIcon);
Vue.component('package-icon',packageIcon);
Vue.component('upload-file-icon',uploadFileIcon);
