import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1f824ec0 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _c0ce989a = () => interopDefault(import('../pages/_lang/advertisements/index.vue' /* webpackChunkName: "pages/_lang/advertisements/index" */))
const _d2afda0e = () => interopDefault(import('../pages/_lang/advertisers/index.vue' /* webpackChunkName: "pages/_lang/advertisers/index" */))
const _fc06892e = () => interopDefault(import('../pages/_lang/chats.vue' /* webpackChunkName: "pages/_lang/chats" */))
const _3435081e = () => interopDefault(import('../pages/_lang/coupons/index.vue' /* webpackChunkName: "pages/_lang/coupons/index" */))
const _fca79130 = () => interopDefault(import('../pages/_lang/customer-service.vue' /* webpackChunkName: "pages/_lang/customer-service" */))
const _5de1de62 = () => interopDefault(import('../pages/_lang/dashboard.vue' /* webpackChunkName: "pages/_lang/dashboard" */))
const _90c4b91c = () => interopDefault(import('../pages/_lang/help-center/index.vue' /* webpackChunkName: "pages/_lang/help-center/index" */))
const _8c330892 = () => interopDefault(import('../pages/_lang/login.vue' /* webpackChunkName: "pages/_lang/login" */))
const _5713b1e1 = () => interopDefault(import('../pages/_lang/questions-bank/index.vue' /* webpackChunkName: "pages/_lang/questions-bank/index" */))
const _26372372 = () => interopDefault(import('../pages/_lang/ratings/index.vue' /* webpackChunkName: "pages/_lang/ratings/index" */))
const _6f43f10e = () => interopDefault(import('../pages/_lang/requestes/index.vue' /* webpackChunkName: "pages/_lang/requestes/index" */))
const _628a6f88 = () => interopDefault(import('../pages/_lang/restore-account.vue' /* webpackChunkName: "pages/_lang/restore-account" */))
const _5f6dfa08 = () => interopDefault(import('../pages/_lang/settings/index.vue' /* webpackChunkName: "pages/_lang/settings/index" */))
const _20109647 = () => interopDefault(import('../pages/_lang/subscriptions/index.vue' /* webpackChunkName: "pages/_lang/subscriptions/index" */))
const _21adb9b2 = () => interopDefault(import('../pages/_lang/surveys.vue' /* webpackChunkName: "pages/_lang/surveys" */))
const _8dc57e4e = () => interopDefault(import('../pages/_lang/users/index.vue' /* webpackChunkName: "pages/_lang/users/index" */))
const _7b320d6b = () => interopDefault(import('../pages/_lang/advertisements/create.vue' /* webpackChunkName: "pages/_lang/advertisements/create" */))
const _1c46b56d = () => interopDefault(import('../pages/_lang/coupons/add.vue' /* webpackChunkName: "pages/_lang/coupons/add" */))
const _1193230a = () => interopDefault(import('../pages/_lang/questions-bank/calender-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/calender-questions" */))
const _ae715a7e = () => interopDefault(import('../pages/_lang/questions-bank/choices-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/choices-questions" */))
const _78757fb8 = () => interopDefault(import('../pages/_lang/questions-bank/csat-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/csat-questions" */))
const _35035000 = () => interopDefault(import('../pages/_lang/questions-bank/nps-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/nps-questions" */))
const _d9b4017c = () => interopDefault(import('../pages/_lang/questions-bank/performance-questions/index.vue' /* webpackChunkName: "pages/_lang/questions-bank/performance-questions/index" */))
const _e4fc7cbc = () => interopDefault(import('../pages/_lang/questions-bank/slider-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/slider-questions" */))
const _0260dd9b = () => interopDefault(import('../pages/_lang/questions-bank/smiley-faces-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/smiley-faces-questions" */))
const _ac5328da = () => interopDefault(import('../pages/_lang/questions-bank/star-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/star-questions" */))
const _a773e1a4 = () => interopDefault(import('../pages/_lang/questions-bank/text-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/text-questions" */))
const _3c558256 = () => interopDefault(import('../pages/_lang/questions-bank/toggle-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/toggle-questions" */))
const _2fac427c = () => interopDefault(import('../pages/_lang/questions-bank/upload-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/upload-questions" */))
const _3c28827e = () => interopDefault(import('../pages/_lang/questions-bank/voice-questions.vue' /* webpackChunkName: "pages/_lang/questions-bank/voice-questions" */))
const _3a28abbe = () => interopDefault(import('../pages/_lang/requestes/google-reviews/index.vue' /* webpackChunkName: "pages/_lang/requestes/google-reviews/index" */))
const _29d3ed28 = () => interopDefault(import('../pages/_lang/settings/advanceSetting.vue' /* webpackChunkName: "pages/_lang/settings/advanceSetting" */))
const _1a40dc2c = () => interopDefault(import('../pages/_lang/settings/packages/index.vue' /* webpackChunkName: "pages/_lang/settings/packages/index" */))
const _1d1174f5 = () => interopDefault(import('../pages/_lang/settings/reports.vue' /* webpackChunkName: "pages/_lang/settings/reports" */))
const _572b6776 = () => interopDefault(import('../pages/_lang/settings/storeInformation.vue' /* webpackChunkName: "pages/_lang/settings/storeInformation" */))
const _481eb8a7 = () => interopDefault(import('../pages/_lang/settings/system-users/index.vue' /* webpackChunkName: "pages/_lang/settings/system-users/index" */))
const _88c258c6 = () => interopDefault(import('../pages/_lang/settings/type-of-business.vue' /* webpackChunkName: "pages/_lang/settings/type-of-business" */))
const _0d639888 = () => interopDefault(import('../pages/_lang/questions-bank/performance-questions/create.vue' /* webpackChunkName: "pages/_lang/questions-bank/performance-questions/create" */))
const _2b1999de = () => interopDefault(import('../pages/_lang/settings/system-users/create-user.vue' /* webpackChunkName: "pages/_lang/settings/system-users/create-user" */))
const _3f31bbd6 = () => interopDefault(import('../pages/_lang/settings/system-users/roles/index.vue' /* webpackChunkName: "pages/_lang/settings/system-users/roles/index" */))
const _55f4992a = () => interopDefault(import('../pages/_lang/settings/system-users/roles/create-role.vue' /* webpackChunkName: "pages/_lang/settings/system-users/roles/create-role" */))
const _050859d0 = () => interopDefault(import('../pages/_lang/settings/system-users/roles/_role/update.vue' /* webpackChunkName: "pages/_lang/settings/system-users/roles/_role/update" */))
const _591fb07e = () => interopDefault(import('../pages/_lang/questions-bank/performance-questions/_id/update.vue' /* webpackChunkName: "pages/_lang/questions-bank/performance-questions/_id/update" */))
const _273f86d6 = () => interopDefault(import('../pages/_lang/settings/packages/_id/update.vue' /* webpackChunkName: "pages/_lang/settings/packages/_id/update" */))
const _73a13889 = () => interopDefault(import('../pages/_lang/settings/system-users/_user/update.vue' /* webpackChunkName: "pages/_lang/settings/system-users/_user/update" */))
const _6ebabd1e = () => interopDefault(import('../pages/_lang/advertisements/_id/index.vue' /* webpackChunkName: "pages/_lang/advertisements/_id/index" */))
const _7b5f710e = () => interopDefault(import('../pages/_lang/advertisers/_store/index.vue' /* webpackChunkName: "pages/_lang/advertisers/_store/index" */))
const _32aa9bd4 = () => interopDefault(import('../pages/_lang/coupons/_coupon/index.vue' /* webpackChunkName: "pages/_lang/coupons/_coupon/index" */))
const _3ae6d7da = () => interopDefault(import('../pages/_lang/help-center/_details.vue' /* webpackChunkName: "pages/_lang/help-center/_details" */))
const _59350213 = () => interopDefault(import('../pages/_lang/reset-password/_restore.vue' /* webpackChunkName: "pages/_lang/reset-password/_restore" */))
const _24ed8c00 = () => interopDefault(import('../pages/_lang/subscriptions/_store/index.vue' /* webpackChunkName: "pages/_lang/subscriptions/_store/index" */))
const _427bde9e = () => interopDefault(import('../pages/_lang/users/_user.vue' /* webpackChunkName: "pages/_lang/users/_user" */))
const _2d111aad = () => interopDefault(import('../pages/_lang/advertisements/_id/update.vue' /* webpackChunkName: "pages/_lang/advertisements/_id/update" */))
const _95fa3686 = () => interopDefault(import('../pages/_lang/advertisers/_store/update.vue' /* webpackChunkName: "pages/_lang/advertisers/_store/update" */))
const _31c5da92 = () => interopDefault(import('../pages/_lang/coupons/_coupon/update.vue' /* webpackChunkName: "pages/_lang/coupons/_coupon/update" */))
const _efdb8bea = () => interopDefault(import('../pages/_lang/subscriptions/_store/update.vue' /* webpackChunkName: "pages/_lang/subscriptions/_store/update" */))
const _3270e5f6 = () => interopDefault(import('../pages/_lang/advertisers/_store/branches/_branch.vue' /* webpackChunkName: "pages/_lang/advertisers/_store/branches/_branch" */))
const _0bc871c4 = () => interopDefault(import('../pages/_lang/subscriptions/_store/branches/_branch.vue' /* webpackChunkName: "pages/_lang/subscriptions/_store/branches/_branch" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/:lang",
    component: _1f824ec0,
    caseSensitive: false,
    name: "lang"
  }, {
    path: "/:lang?/advertisements",
    component: _c0ce989a,
    caseSensitive: false,
    name: "lang-advertisements"
  }, {
    path: "/:lang?/advertisers",
    component: _d2afda0e,
    caseSensitive: false,
    name: "lang-advertisers"
  }, {
    path: "/:lang?/chats",
    component: _fc06892e,
    caseSensitive: false,
    name: "lang-chats"
  }, {
    path: "/:lang?/coupons",
    component: _3435081e,
    caseSensitive: false,
    name: "lang-coupons"
  }, {
    path: "/:lang?/customer-service",
    component: _fca79130,
    caseSensitive: false,
    name: "lang-customer-service"
  }, {
    path: "/:lang?/dashboard",
    component: _5de1de62,
    caseSensitive: false,
    name: "lang-dashboard"
  }, {
    path: "/:lang?/help-center",
    component: _90c4b91c,
    caseSensitive: false,
    name: "lang-help-center"
  }, {
    path: "/:lang?/login",
    component: _8c330892,
    caseSensitive: false,
    name: "lang-login"
  }, {
    path: "/:lang?/questions-bank",
    component: _5713b1e1,
    caseSensitive: false,
    name: "lang-questions-bank"
  }, {
    path: "/:lang?/ratings",
    component: _26372372,
    caseSensitive: false,
    name: "lang-ratings"
  }, {
    path: "/:lang?/requestes",
    component: _6f43f10e,
    caseSensitive: false,
    name: "lang-requestes"
  }, {
    path: "/:lang?/restore-account",
    component: _628a6f88,
    caseSensitive: false,
    name: "lang-restore-account"
  }, {
    path: "/:lang?/settings",
    component: _5f6dfa08,
    caseSensitive: false,
    name: "lang-settings"
  }, {
    path: "/:lang?/subscriptions",
    component: _20109647,
    caseSensitive: false,
    name: "lang-subscriptions"
  }, {
    path: "/:lang?/surveys",
    component: _21adb9b2,
    caseSensitive: false,
    name: "lang-surveys"
  }, {
    path: "/:lang?/users",
    component: _8dc57e4e,
    caseSensitive: false,
    name: "lang-users"
  }, {
    path: "/:lang?/advertisements/create",
    component: _7b320d6b,
    caseSensitive: false,
    name: "lang-advertisements-create"
  }, {
    path: "/:lang?/coupons/add",
    component: _1c46b56d,
    caseSensitive: false,
    name: "lang-coupons-add"
  }, {
    path: "/:lang?/questions-bank/calender-questions",
    component: _1193230a,
    caseSensitive: false,
    name: "lang-questions-bank-calender-questions"
  }, {
    path: "/:lang?/questions-bank/choices-questions",
    component: _ae715a7e,
    caseSensitive: false,
    name: "lang-questions-bank-choices-questions"
  }, {
    path: "/:lang?/questions-bank/csat-questions",
    component: _78757fb8,
    caseSensitive: false,
    name: "lang-questions-bank-csat-questions"
  }, {
    path: "/:lang?/questions-bank/nps-questions",
    component: _35035000,
    caseSensitive: false,
    name: "lang-questions-bank-nps-questions"
  }, {
    path: "/:lang?/questions-bank/performance-questions",
    component: _d9b4017c,
    caseSensitive: false,
    name: "lang-questions-bank-performance-questions"
  }, {
    path: "/:lang?/questions-bank/slider-questions",
    component: _e4fc7cbc,
    caseSensitive: false,
    name: "lang-questions-bank-slider-questions"
  }, {
    path: "/:lang?/questions-bank/smiley-faces-questions",
    component: _0260dd9b,
    caseSensitive: false,
    name: "lang-questions-bank-smiley-faces-questions"
  }, {
    path: "/:lang?/questions-bank/star-questions",
    component: _ac5328da,
    caseSensitive: false,
    name: "lang-questions-bank-star-questions"
  }, {
    path: "/:lang?/questions-bank/text-questions",
    component: _a773e1a4,
    caseSensitive: false,
    name: "lang-questions-bank-text-questions"
  }, {
    path: "/:lang?/questions-bank/toggle-questions",
    component: _3c558256,
    caseSensitive: false,
    name: "lang-questions-bank-toggle-questions"
  }, {
    path: "/:lang?/questions-bank/upload-questions",
    component: _2fac427c,
    caseSensitive: false,
    name: "lang-questions-bank-upload-questions"
  }, {
    path: "/:lang?/questions-bank/voice-questions",
    component: _3c28827e,
    caseSensitive: false,
    name: "lang-questions-bank-voice-questions"
  }, {
    path: "/:lang?/requestes/google-reviews",
    component: _3a28abbe,
    caseSensitive: false,
    name: "lang-requestes-google-reviews"
  }, {
    path: "/:lang?/settings/advanceSetting",
    component: _29d3ed28,
    caseSensitive: false,
    name: "lang-settings-advanceSetting"
  }, {
    path: "/:lang?/settings/packages",
    component: _1a40dc2c,
    caseSensitive: false,
    name: "lang-settings-packages"
  }, {
    path: "/:lang?/settings/reports",
    component: _1d1174f5,
    caseSensitive: false,
    name: "lang-settings-reports"
  }, {
    path: "/:lang?/settings/storeInformation",
    component: _572b6776,
    caseSensitive: false,
    name: "lang-settings-storeInformation"
  }, {
    path: "/:lang?/settings/system-users",
    component: _481eb8a7,
    caseSensitive: false,
    name: "lang-settings-system-users"
  }, {
    path: "/:lang?/settings/type-of-business",
    component: _88c258c6,
    caseSensitive: false,
    name: "lang-settings-type-of-business"
  }, {
    path: "/:lang?/questions-bank/performance-questions/create",
    component: _0d639888,
    caseSensitive: false,
    name: "lang-questions-bank-performance-questions-create"
  }, {
    path: "/:lang?/settings/system-users/create-user",
    component: _2b1999de,
    caseSensitive: false,
    name: "lang-settings-system-users-create-user"
  }, {
    path: "/:lang?/settings/system-users/roles",
    component: _3f31bbd6,
    caseSensitive: false,
    name: "lang-settings-system-users-roles"
  }, {
    path: "/:lang?/settings/system-users/roles/create-role",
    component: _55f4992a,
    caseSensitive: false,
    name: "lang-settings-system-users-roles-create-role"
  }, {
    path: "/:lang?/settings/system-users/roles/:role/update",
    component: _050859d0,
    caseSensitive: false,
    name: "lang-settings-system-users-roles-role-update"
  }, {
    path: "/:lang?/questions-bank/performance-questions/:id/update",
    component: _591fb07e,
    caseSensitive: false,
    name: "lang-questions-bank-performance-questions-id-update"
  }, {
    path: "/:lang?/settings/packages/:id/update",
    component: _273f86d6,
    caseSensitive: false,
    name: "lang-settings-packages-id-update"
  }, {
    path: "/:lang?/settings/system-users/:user?/update",
    component: _73a13889,
    caseSensitive: false,
    name: "lang-settings-system-users-user-update"
  }, {
    path: "/:lang?/advertisements/:id",
    component: _6ebabd1e,
    caseSensitive: false,
    name: "lang-advertisements-id"
  }, {
    path: "/:lang?/advertisers/:store",
    component: _7b5f710e,
    caseSensitive: false,
    name: "lang-advertisers-store"
  }, {
    path: "/:lang?/coupons/:coupon",
    component: _32aa9bd4,
    caseSensitive: false,
    name: "lang-coupons-coupon"
  }, {
    path: "/:lang?/help-center/:details?",
    component: _3ae6d7da,
    caseSensitive: false,
    name: "lang-help-center-details"
  }, {
    path: "/:lang?/reset-password/:restore?",
    component: _59350213,
    caseSensitive: false,
    name: "lang-reset-password-restore"
  }, {
    path: "/:lang?/subscriptions/:store",
    component: _24ed8c00,
    caseSensitive: false,
    name: "lang-subscriptions-store"
  }, {
    path: "/:lang?/users/:user",
    component: _427bde9e,
    caseSensitive: false,
    name: "lang-users-user"
  }, {
    path: "/:lang?/advertisements/:id/update",
    component: _2d111aad,
    caseSensitive: false,
    name: "lang-advertisements-id-update"
  }, {
    path: "/:lang?/advertisers/:store/update",
    component: _95fa3686,
    caseSensitive: false,
    name: "lang-advertisers-store-update"
  }, {
    path: "/:lang?/coupons/:coupon/update",
    component: _31c5da92,
    caseSensitive: false,
    name: "lang-coupons-coupon-update"
  }, {
    path: "/:lang?/subscriptions/:store/update",
    component: _efdb8bea,
    caseSensitive: false,
    name: "lang-subscriptions-store-update"
  }, {
    path: "/:lang?/advertisers/:store/branches/:branch?",
    component: _3270e5f6,
    caseSensitive: false,
    name: "lang-advertisers-store-branches-branch"
  }, {
    path: "/:lang?/subscriptions/:store/branches/:branch?",
    component: _0bc871c4,
    caseSensitive: false,
    name: "lang-subscriptions-store-branches-branch"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
