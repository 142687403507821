//import i18n from '~/plugins/i18n'
import Vue from 'vue'
export default {
  setAllReviews: (state,data) => {
     state.allReviews=data
  },
  setOneReview: (state, data) => {
    state.oneReview = data.content;
  },  
  // remove that review from the list and close delete confirmation dialog.
  deleteReview:(state,data)=>{
   var index= state.allReviews.data.find(review=>review.review_id===data)
    if(index!=-1){
      state.allReviews.data.splice(index, 1)
      state.deleteDialog=false;
    }
    
  },
  setDeleteDialog: (state,data) => {
    state.deleteDialog=data

  },
  insertAlertReview:(state,data)=>{
    state.alertReviewDialog=false
  },
  setAlertReviewDialog:(state,data)=>{
    state.alertReviewDialog=data
  }


}
