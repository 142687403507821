export default { 
  async loadAllQuestions({ commit },data) {
    commit('app/loadingStart', null, { root: true });
    await this.$axios.get(`questions?category=${data.category}&type=${data.type}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setAllQuestion', respo.data.data);
      }
    });
  },
  async loadOneQuestions({ commit }, questionId) {
    commit('app/loadingStart', null, { root: true });

 return   await this.$axios
      .get(`questions/${questionId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneQuestion', respo.data.data);
          return respo.data.data.content
        }
      });
  },
  async saveNewQuestion({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`questions`, data).then((respo) => {
      if (respo.status === 200) {
      
          commit('InsertQuestion', {
            ...respo.data.data.content
          });
      
        commit('app/successMessage', 'questionAdded', { root: true });
      }
    });
  },
  async editOneQuestion({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`questions/${data.questionId}`, data.questionDetails)
      .then((respo) => {
        if (respo.status === 200) {
       
            commit('UpdateQuestion', {
              ...respo.data.data.content
            });
        
          commit('app/successMessage', 'questionUpdated', { root: true });
        }
      });
  },
  async deleteOneQuestion({ commit }, questionId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .delete(`questions/${questionId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('deleteQuestion', questionId);
          commit('app/successMessage', 'questionDeleted', { root: true });
        }
      });
  },
};
