import { DxfWriter, HatchPolylineBoundary, vertex, HatchBoundaryPaths, HatchPredefinedPatterns, pattern, LWPolylineFlags, point2d } from "@tarikjabiri/dxf";
export default {
  // loading reviews
  async loadAllReviews({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `reviews?page=${data.page}&per_page=${data.per_page}&sort_by=${data.sort_by}&sort_direction=${data.sort_direction}&branch=${
          data.branch
        }&month=${data.month}&star=${
          data.star
        }&question=${data.question}&type=${data.type}&user=${data.user}${data.read_reviews ? `&read_reviews=true` : ``}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAllReviews", respo.data.data);
          
        }
      })
      
  },
  // loadin review details
   async loadOneReview({ commit }, reviewId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.get(`reviews/${reviewId}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setOneReview', respo.data.data);
      }
    });
  }, 
  async deleteOneReview({ commit }, reviewId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .delete(`/store-review/${reviewId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("deleteReview", reviewId);
          commit("app/successMessage", "reviewDeleted", { root: true });

          
        }
      })
      
  },
  async saveNewAlertReview({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .post(`/send-alert`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("insertAlertReview", respo.data);
          commit("app/successMessage", "alertSent", { root: true });

          
        }
      })
      
  },
  async exportToExcel({ commit, dispatch }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `review-group-by-questions?page=all&per_page=all&sort_by=${data.sort_by}&sort_direction=${data.sort_direction}&branch=${data.branch}&month=${data.month}&star=${data.star}&question=${
          data.question
        }&type=${data.type}&user=${data.user}${
          data.read_reviews ? `&read_reviews=true` : ``
        }`,
        null,
        {
          responseType: 'blob'
        }
      )
      .then((respo) => {
     if (respo.status === 200) {
          // export returned data

          const link = document.createElement('a');
          link.download = data.fileName + '_' + this.$moment().format('YYYY-MM-DD') + '.'+data.type;
          link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + respo.data;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

        }
      })
      
  }, 

 
};
