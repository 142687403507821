export default {
  setAllUsers: (state, data) => {
    state.allUsers = data.data;
  },
  setOneUser: (state, data) => {
    state.oneUser = data.content;
  },

  // find user and update the state with the new data + disable dialog
  setChangeUserState: (state, data) => {
    var index = state.allUsers.content.findIndex((s) => s.id === data.id);
    if (index > -1) {
      state.allUsers.content[index].state = data.state;
    }
    if (state.oneUser) {
      state.oneUser.state = data.state;
    }
    state.disableDialog = false;
    state.enableDialog = false;
  },
  setEnableDialog: (state, data) => {
    state.enableDialog = data;
  },
  setDisableDialog: (state, data) => {
    state.disableDialog = data;
  },
  setDeleteDialog: (state, data) => {
    state.deleteDialog = data;
  },
  deleteUser: (state, data) => {
    var index = state.allUsers.content.findIndex((s) => s.id === data);
    if (index > -1) {
      state.allUsers.content.splice(index, 1);
    }
    state.oneUser = null;
    state.deleteDialog = false;
  }, 
};
