export default {
  getAllReviews(state) {
    return state.allReviews
  },
  getOneReview(state) {
    return state.oneReview;
  },
  getDeleteDialog(state) {
    return state.deleteDialog
  },
  getAlertReviewDialog(state) {
    return state.alertReviewDialog
  },
}
