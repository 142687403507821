export default {
  async loadAllStores({ commit }, data) {
    if (!data.noLoading) commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `get-stores?page=${data.page}&per_page=${data.per_page}&state=${
          data.state
        }&type=${data.type}&search=${data.search ? data.search : ""}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAllStores", respo.data);

          
        }
      })
      
  },
  async loadOneStore({ commit }, storeId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(`get-stores/${storeId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setOneStore", respo.data.data);

          
        }
      })
      
  },
  async changeStoreStateActivate({ commit }, storeId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .put(`store-state-change/${storeId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("users/setEnableDialog", false, { root: true });
          commit("setChangeStoreState", { id: storeId, state: "1" });
          commit("app/successMessage", "storeActivated", { root: true });
          
        }
      })
      
  },
  async changeStoreStateDisable({ commit }, storeId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .put(`store-state-change/${storeId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("users/setDisableDialog", false, { root: true });
          commit("setChangeStoreState", { id: storeId, state: "2" });
          commit("app/successMessage", "storeDisabled", { root: true });
          
        }
      })
      
  },
  async deleteOneStore({ commit }, storeId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .delete(`delete-store/${storeId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("users/setDeleteDialog", false, { root: true });
          commit("deleteStore", storeId);
          commit("app/successMessage", "storeDeleted", { root: true });
          
        }
      })
      
  }, 
  async changeStoreInfo({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .patch(`/stores/${data.id}`, data)
      .then((respo) => {
        if (respo.status === 200) {
          this.$router.back();
          commit("app/successMessage", "storeUpdated", { root: true });
          
        }
      })
      
  },
  
};
