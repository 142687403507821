export default {
    getlang(state) {
      return state.language
    },
    getloading (state) {
      return state.loading
    },
    getdrawer (state) {
      return state.drawer
    },
    
    getImageUrl (state) {
      return state.imageUrl
    },
  }
