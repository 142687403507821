//import i18n from '~/plugins/i18n'
import Vue from 'vue'
export default {
  setStatistics: (state,data) => {
     state.statistics= data.data
  }, 
  addNEwMessage: (state) => {
    state.statistics.no_read_messages= state.statistics.no_read_messages+1
 }, 

}
