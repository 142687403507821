export default {
    getIsLoggedIn (state) {
      return state.isLoggedIn
    },  
    getUpdateDialog(state) {
      return state.updateDialog
    },
    getEmailSent(state) {
      return state.EmailSent
    },
  } 
  