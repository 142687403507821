export default {
  setAllCategories: (state, data) => {
    state.allCategories = data.content;
  },
  setAllCategory: (state, data) => {
    state.allCategories = data.content;
  },
  setOneCategory: (state, data) => {
    state.oneCategory = data.content;
  },
  InsertCategory: (state, data) => {
    state.allCategories.push(data);
    state.addDialog = true;
  },
  setAddDialog: (state) => {
    state.addDialog = false;
  },
  //   update the category with the new data 
  UpdateCategory: (state, data) => {
    const index = state.allCategories.find((item) => item.id == data.id);
    if (index) {
      Object.assign(index, data);
      state.oneCategory = data;
      state.updateDialog = true;
    }
  },
  setUpdateDialog: (state) => {
    state.updateDialog = false;
  },
  //   delete category from the list
  deleteCategory: (state, categoryId) => {
    var index = state.allCategories.findIndex((item) => item.id === categoryId);
    if (index > -1) {
      state.allCategories.splice(index, 1);
    }
    state.deleteDialog = true;
  },
  setDeleteDialog: (state) => {
    state.deleteDialog = false;
  },
  // . It finds the index of a category in the allCategories array in the state object based on the stores_category_id in the data object. If the category is found, it inserts the data object into the surveys array of that category at the beginning.
  InsertSurvey: (state, data) => {
    let categoryIndex = state.allCategories.findIndex(item => item.id == data.stores_category_id);
    if (categoryIndex > -1) {
      state.allCategories[categoryIndex].surveys.unshift(data)

    }
  },
  // It finds the category by its ID, then finds the survey inside that category by its ID, and updates it with the new data using Object.assign.
  UpdateSurvey: (state, data) => {
    let categoryIndex = state.allCategories.findIndex(item => item.id == data.stores_category_id);

    if (categoryIndex > -1) {
      const index = state.allCategories[categoryIndex].surveys.find((item) => item.id == data.id)
      if (index) {
        Object.assign(index, data);

      }
    }
  },
  // It finds the index of a category in the state based on a specific condition, and if the category is found, it updates the state of a survey within that category.
  setSurveyState: (state, data) => { 
    let categoryIndex = state.allCategories.findIndex(item => item.id == data.stores_category_id);
    if (categoryIndex > -1) {
      let survey = state.allCategories[categoryIndex].surveys.findIndex(survey => survey.id === data.id)
      state.allCategories[categoryIndex].surveys[survey].state = data.state == 1 ? 2 : 1;

    }
  },
  //  It finds the index of a category in the allCategories array based on the stores_category_id in the data. If the category is found, it then finds the index of a survey inside that category and removes it from the surveys array.
  deleteSurvey: (state, data) => {
    var categoryIndex = state.allCategories.findIndex((item) => item.id === data.stores_category_id);
    if (categoryIndex > -1) {
      const index = state.allCategories[categoryIndex].surveys.findIndex((item) => item.id == data.id)
      if (index > -1) {
        state.allCategories[categoryIndex].surveys.splice(index, 1);
      }
    }
  },
  // It finds the index of the category, then uses that index to find and remove the survey from the category's list of surveys.
  deleteSureyFromCategory: (state, data) => {
    var categoryIndex = state.allCategories.findIndex((item) => item.id === data.old_section);
    if (categoryIndex > -1) {
      const index = state.allCategories[categoryIndex].surveys.findIndex((item) => item.id == data.id)
      if (index > -1) {
        state.allCategories[categoryIndex].surveys.splice(index, 1);
      }
    }
  }
};
