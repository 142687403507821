var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: "textboxContainer " + _vm.classes },
    [
      _vm.label != null
        ? _c(
            "v-layout",
            { attrs: { "justify-space-between": "", "align-center": "" } },
            [
              _c(
                "label",
                {
                  class: _vm.packageDisabled
                    ? "   form__label diabled_field"
                    : "   form__label",
                },
                [
                  _vm._v(_vm._s(_vm.label + " ")),
                  _vm.required
                    ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
                    : _vm._e(),
                  _vm.subLabel !== null
                    ? _c(
                        "span",
                        {
                          staticClass: "text-regular-14 text-font-secondary",
                          staticStyle: {
                            "margin-right": "4px",
                            "margin-left": "4px",
                            "line-height": "12px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.subLabel))]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm._t("link"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-text-field",
        _vm._b(
          {
            attrs: {
              solo: "",
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              "append-icon": _vm.appendicon,
              value: _vm.value,
              rules: _vm.typeofrules,
              "hide-details": "auto",
              type: _vm.type,
            },
            on: {
              input: function ($event) {
                return _vm.updateValue($event)
              },
              "click:append": _vm.append,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function () {
                    return [_vm._t("icon")]
                  },
                  proxy: true,
                },
                {
                  key: "prepend-inner",
                  fn: function () {
                    return [_vm._t("icon2")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "v-text-field",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }