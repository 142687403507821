export default () => ({
    locales: ['ar', 'en'],
    language:{ locale: 'ar',
    rtl:'rtl',
    rtl2:'rtl.',
    direction:'right',},
   
    messages: 'success',
    loading:false,
    drawer: null,
    imageUrl:   process.env.baseUrl ,
  })
