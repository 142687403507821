export default {
   
  async loadAllPointsInfo({ commit }) {
    commit("app/loadingStart", null, { root: true });
    await this.$axios
      .get(this.$config.baseURL + `api/v1/backend/store-points-info`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAllPointsInfo", respo.data.data.content);
        }
      });
  },
  
};
