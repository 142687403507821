export default {
  
  getAllCategories(state) {
    return state.allCategories;
  },
  getOneCategory(state) {
    return state.oneCategory;
  },
  getAddDialog(state) {
    return state.addDialog;
  },
  getUpdateDialog(state) {
    return state.updateDialog;
  },
  getDeleteDialog(state) {
    return state.deleteDialog;
  },
};
