var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", transition: "dialog-transition" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { "justify-center": "", "align-center": "" } },
            [
              _c(
                "v-card",
                {
                  staticStyle: { margin: "66px 18px 0px" },
                  attrs: {
                    "max-width": "400",
                    width: "400",
                    "min-width": "300",
                  },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "justify-content-end pa-0",
                      staticStyle: { margin: "16px 14px 10px" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "wameed-btn sm-icon-btn border-input bg-input px-0",
                          attrs: { text: "", small: "" },
                          on: {
                            click: function ($event) {
                              _vm.show = false
                            },
                          },
                        },
                        [
                          _c("close-icon", {
                            staticClass: "svg-font-sub my-1",
                            staticStyle: { width: "12px", height: "12px" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    {
                      staticClass: "feedback-modal py-0",
                      staticStyle: { height: "100%", padding: "38px" },
                      attrs: { "justify-center": "", "align-center": "" },
                    },
                    [
                      _c(
                        "v-flex",
                        { staticStyle: { "max-width": "270px" } },
                        [
                          _c(
                            "v-layout",
                            {
                              staticStyle: { height: "100%" },
                              attrs: {
                                "justify-center": "",
                                "align-center": "",
                                column: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "feedback-icon bg-light-danger rounded-6 text-center d-flex justify-center align-center",
                                  attrs: { text: "" },
                                },
                                [
                                  _vm.stop == 1
                                    ? _c("delete-icon", {
                                        staticClass: "my-3 mx-auto svg-danger",
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.stop == 2
                                    ? _c("stop-icon", {
                                        staticClass: "my-3 mx-auto svg-danger",
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.stop == 3
                                    ? _c("logout-icon", {
                                        staticClass: "my-3 mx-auto svg-danger",
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.stop == 4
                                    ? _c("info-icon", {
                                        staticClass:
                                          "my-3 mx-auto svg-circle-danger svg-danger",
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "nodataTitle",
                                  staticStyle: {
                                    "margin-top": "18px",
                                    "margin-bottom": "15px",
                                    padding: "0px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.title) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "nodataSubTitle" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.subTitle) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                {
                                  staticClass: "ma-5",
                                  staticStyle: { gap: "14px" },
                                  attrs: { "justify-center": "" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "wameed-btn text-regular-13 text-danger btn-light-danger w-50",
                                      staticStyle: {
                                        "min-width": "124px !important",
                                      },
                                      attrs: { text: "" },
                                      on: { click: _vm.emitAction },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.btntitle) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "wameed-btn text-regular-13 text-font-sub btn-light w-50",
                                      staticStyle: {
                                        "min-width": "124px !important",
                                      },
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.show = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("actions.cancel")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }