export default {

  setTutorials(state, data) {
    state.tutorials = data;
  },
  setTutorialsDetails(state, data) {
    state.tutorialsDetails = data;
  },
  setTutorialsSections(state, data) {
    state.tutorialsSections = data;
  },
  setTutorialsSectionsDetails(state, data) {
    state.tutorialsSectionsDetails = data;
  },
  // insert new tutorial into array
  InsertTutorial: (state, data) => {
    let currentSection = state.tutorialsSections.findIndex(item => item.id === data.tutorial_sections_id)
    if (currentSection > -1)
      state.tutorialsSections[currentSection].tutorials.unshift(data);
  },
  // update  tutorial with the new data
  UpdateTutorial: (state, data) => {
    let oldSection = state.tutorialsSections.findIndex(item => item.id === data.old_section_id)
    if (oldSection > -1) {
      const index = state.tutorialsSections[oldSection].tutorials.findIndex((item) => item.id === data.id);
      if (index > -1) {
        state.tutorialsSections[oldSection].tutorials.splice(index, 1);
      }
    }
    let currentSection = state.tutorialsSections.findIndex(item => item.id === data.tutorial_sections_id)
    if (currentSection > -1) {

      state.tutorialsSections[currentSection].tutorials.unshift(data);
    }

  },
  // delete  tutorial from list
  deleteTutorial: (state, data) => {
    let currentSection = state.tutorialsSections.findIndex(item => item.id === data.tutorial_sections_id)
    if (currentSection > -1) {
      const index = state.tutorialsSections[currentSection].tutorials.findIndex((item) => item.id === data.id);
      if (index > -1) {
        state.tutorialsSections[currentSection].tutorials.splice(index, 1);
      }
    }
  },
  // insert new section into array
  InsertSection: (state, data) => {
    state.tutorialsSections.unshift(data);
  },
  // update one section with the new data
  UpdateSection: (state, data) => {
    const index = state.tutorialsSections.find((item) => item.id === data.id);
    if (index) {
      Object.assign(index, data);
      state.tutorialsSectionsDetails = data;
    }
  },
  // delete  section from list
  deleteSection: (state, id) => {
    var index = state.tutorialsSections.findIndex((item) => item.id === id);
    if (index > -1) {
      state.tutorialsSections.splice(index, 1);
    }
  },

};
