import { render, staticRenderFns } from "./drawerNew.vue?vue&type=template&id=221316fa"
import script from "./drawerNew.vue?vue&type=script&lang=js"
export * from "./drawerNew.vue?vue&type=script&lang=js"
import style0 from "./drawerNew.vue?vue&type=style&index=0&id=221316fa&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/admin/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ogulf/nodevenv/public_html/admin/16/lib/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('221316fa')) {
      api.createRecord('221316fa', component.options)
    } else {
      api.reload('221316fa', component.options)
    }
    module.hot.accept("./drawerNew.vue?vue&type=template&id=221316fa", function () {
      api.rerender('221316fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/core/drawerNew.vue"
export default component.exports