export default {
  
  getAllPackages(state) {
    return state.allPackages;
  },
  getOnePackage(state) {
    return state.onePackage;
  },
  
};
