export default {
  async loadDasboardStatisitcs({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(`dashboard-details`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setStatistics", respo.data);
          
        }
      })
      
  },
};
