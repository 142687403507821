export default {
  async loadAllEmails({ commit }) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(`email-messages`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAllEmails", respo.data.data);

          
        }
      })
      
  },
  async loadOneEmail({ commit }, userId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(`email-messages/${userId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setOneEmail", respo.data.data);

          
        }
      })
      
  }, 
  async sendEmail({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .post(`email-messages/send`,data)
      .then((respo) => {
        if (respo.status === 200) {

          
        }
      })
      
  }, 
};
