const permissions = {
  
       superEditStoreData: 'edit store data',
       superEditTax: 'super edit value added tax',
     
       superReadSystemUsers: 'super read system users',
       superAddSystemUsers: 'super add system users',
       superEditSystemUsers: 'super edit system users',
       superDeleteSystemUsers: 'super delete system users',
     
       superReadRoles: 'super read roles',
       superAddRoles: 'super add roles',
       superEditRoles: 'super edit roles',
       superDeleteRoles: 'super delete roles',
     
       // ====== Super Admin Permissions ======
       superReadDashboard: 'super read dashboard',
       // transactions permissions
       superReadTransactions: 'super read transactions',
       superEditTransactions: 'super edit transactions',
       // users permissions
       superReadUsers: 'super read users',
       superEditUsers: 'super edit users',
       superDeleteUsers: 'super delete users',
       // advertisers permissions
       superReadAdvertisers: 'super read advertisers',
       superSendAnAlertAdvertisers: 'super Send an alert advertisers',
       superEditAdvertisers: 'super edit advertisers',
       superDeleteAdvertisers: 'super delete advertisers',
       // points permissions
       // questions permissions
       superReadQuestions: 'super read questions',
       superAddQuestions: 'super add questions',
       superEditQuestions: 'super edit questions',
       superDeleteQuestions: 'super delete questions',
       // subscriptions permissions
       superReadSubscriptions: 'super read subscriptions',
       // coupons permissions
       superReadCoupons: 'super read coupons',
       superAddCoupons: 'super add coupons',
       superEditCoupons: 'super edit coupons',
       superDeleteCoupons: 'super delete coupons',
       // reviews permissions
       superReadReviews: 'super read reviews',
       superExportReviews: 'super export reviews',
       // chats permissions
       superReadChats: 'super read chats',
       superReadCustomerService: 'super read customer service',
       

       superReadAdvertisements: 'super read advertisements',
       superAddAdvertisements: 'super add advertisements',
       superEditAdvertisements: 'super edit advertisements',
       superDeleteAdvertisements: 'super delete advertisements',
       
       // settings permissions
       superReadReports: 'super read reports',
       superExportReports: 'super export reports',

       superReadTypeOfBusiness: 'super read type of business',
       superAddTypeOfBusiness: 'super add type of business',
       superEditTypeOfBusiness: 'super edit type of business',
       superDeleteTypeOfBusiness: 'super delete type of business',
       superManageTutorials:"super manage tutorials"
     };
     
     export { permissions };
      