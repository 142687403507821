export default {
  async loadTutorials({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `tutorials/sections`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setTutorials", respo.data.data.content);

          
        }
      })
      
  },
    async reOrderTutorials({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .post(
        `tutorials/order`,
        data
      )
      .then((respo) => {
        if (respo.status === 200) {

          
        }
      })
      
  },
  
  async loadTutorialsDetails({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `tutorials/${data}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setTutorialsDetails", respo.data.data.content);

          
        }
      })
      
  },
  async loadTutorialsSections({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `tutorials/sections`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setTutorialsSections", respo.data.data.content);

          
        }
      })
      
  },
  async loadTutorialsSectionsDetails({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `tutorials/sections/${data}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setTutorialsSectionsDetails", respo.data.data.content);

          
        }
      })
      
  },
  async createSection({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .post(
        `tutorials/sections`,
        data
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('InsertSection', {
            ...respo.data.data.content
          });
          commit('app/successMessage', 'sectionAdded', { root: true });
          
        }
      })
      
  },

  async editSection({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put( `tutorials/sections/${data.sectionId}`, data.sectionDetails)
      .then((respo) => {
        if (respo.status === 200) {
       
            commit('UpdateSection', {
              ...respo.data.data.content
            });
        
          commit('app/successMessage', 'sectionUpdated', { root: true });
        }
      });
  },
  async deleteTutorialsSections({dispatch, commit }, sectionId) {
    commit('app/loadingStart', null, { root: true });

   return await this.$axios
      .delete(`tutorials/sections/${sectionId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          dispatch('loadTutorialsSections');
          commit('app/successMessage', 'sectionDeleted', { root: true });
        }
      });
  },
  async createTutorial({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .post(
        `tutorials`,
        data
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit('InsertTutorial', {
            ...respo.data.data.content
          });
          
          commit('app/successMessage', 'tutorialAdded', { root: true });
        }
      })
      
  },

  async editTutorial({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put( `tutorials/${data.tutorialId}`, data.tutorialDetails)
      .then((respo) => {
        if (respo.status === 200) {
       
            commit('UpdateTutorial', {
              ...respo.data.data.content,
              old_section_id:data.tutorialDetails.old_section_id
            });
        
          commit('app/successMessage', 'tutorialUpdated', { root: true });
        }
      });
  },
  async deleteTutorials({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

   return await this.$axios
      .delete(`tutorials/${data.id}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('deleteTutorial', data);
          commit('app/successMessage', 'tutorialDeleted', { root: true });
        }
      });
  },
  
   
};
