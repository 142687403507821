import Vue from "vue";
export default {
  setAllAdvertisements: (state, data) => {
    state.allAdvertisements = data.data;
  },
  setAdvertisementsStores: (state, data) => {
    state.advertisementsStores = data;
  },
  setAdvertisors: (state, data) => {
    state.advertisors = data;
  },
  setOneAdvertisement: (state, data) => {
    state.oneAdvertisement = data.content;
  },
  setChangeAdvertisementState: (state, data) => {
    if (state.allAdvertisements && state.allAdvertisements.content)
      var index = state.allAdvertisements.content.findIndex((s) => s.id == data.id);
    if (index > -1) {
      state.allAdvertisements.content[index].state = data.state;
    }
    if (state.oneAdvertisement) {
      state.oneAdvertisement.state = data.state;
    }
  },
  deleteAdvertisement: (state, data) => {
    if (state.allAdvertisements && state.allAdvertisements.content)
      var index = state.allAdvertisements.content.findIndex((s) => s.id == data);
    if (index > -1) {
      state.allAdvertisements.content.splice(index, 1);
    }
    state.oneAdvertisement = null;
  },
};
