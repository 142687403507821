<template>
  <client-only>
    <v-app :class="$i18n.locale" :id="$i18n.locale" v-show="showcontent">
      <div class="cp-left" id="mainCpDiv" style="height: 100%">
        <drawer-new />
        <toolbar />

        <v-btn v-scroll="onScroll" v-show="fab" dark small fixed bottom
          style="margin-bottom: 50px;min-width:10px; padding: 20px 10px  !important;z-index:99;"
          :style="$i18n.locale == 'ar' ? 'left:10px;' : 'right:10px;'" color="#8363AA" @click="toTop">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-lazy v-if="getIsLoggedIn" v-model="isviewActive" :options="{
      threshold: 0.5,
    }" transition="fade-transition">
          <CoreControlView />
        </v-lazy>
      </div>

      <messages />
    </v-app>
  </client-only>
</template>

<script>
import DrawerNew from "../components/core/drawerNew.vue";
import CoreControlView from "../components/core/controlView.vue";
import Toolbar from "../components/core/Toolbar.vue";
import { mapGetters } from "vuex";
import Messages from "../components/material/messages.vue";

export default {
  components: { DrawerNew, Toolbar, Messages, CoreControlView },
  head() {
    return {
      htmlAttrs: {
        lang: this.language.locale,
        direction: this.language.rtl,
        dir: this.language.rtl,
        style: `direction: ${this.language.rtl}`,
      },


    };
  },
  data: () => ({
    fab: false,
    isviewActive: false,
    showcontent: false,
  }),
  created() {
 

  },
  computed: {
    ...mapGetters({ language: "app/getlang", getIsLoggedIn: 'auth/getIsLoggedIn' }),
  },
  mounted() {
    this.$nextTick(() => {
      this.$nuxt.$loading.start();
      setTimeout(() => {
        this.$nuxt.$loading.finish();
        this.showcontent = true;
      }, 2000);
    });
    this.$vuetify.rtl = this.$i18n.locale == 'ar';
 


  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
