export default {
  setLoggedInuser: (state,data) => { 
    state.isLoggedIn = data
  }, 
  updatePassword: (state) => {
    
    state.updateDialog=true
  }, 
  setUpdateDialog: (state,data) => {
    state.updateDialog=data

  },
  setLoggedOutuser(state){
    state.isLoggedIn=null
  },
  setCheckEmail(state,data){
    state.EmailSent=data

  }
}
