export default function ({ $axios, isHMR, app, store, route, error, redirect }) {
  //default Lang
  const defaultLocale = app.i18n.fallbackLocale
  //local cookies Lang
  const localLanguage = app.$cookies.get('Language', { parseJSON: true })
  //route param
  const locale = route.params.lang || defaultLocale
  if (isHMR) {
    return
  }
  store.dispatch('auth/checkLoggedIn')
  if (localLanguage === undefined) {
    var data = {
      locale: 'ar',
      rtl: 'rtl',
      direction: 'right',
    }
    if (locale !== undefined) {


      if (!store.state.app.locales.includes(locale)) {
        return redirect('/ar')
      }
      else {
        store.commit('app/SET_LANG', {
          locale: locale == 'ar' ? 'ar' : 'en',
          rtl: locale == 'ar' ? 'rtl' : 'ltr',
          rtl2: locale == 'ar' ? 'rtl.' : '',
          direction: locale == 'ar' ? 'right' : 'left',
        })

      }

    }

    app.$cookies.set('Language', JSON.stringify(store.state.app.language), { parseJSON: false })

  }
  else {
    if (locale !== undefined) {
      if (!store.state.app.locales.includes(locale)) {
        return redirect('/ar')

      } else {
        store.commit('app/SET_LANG', {
          locale: locale == 'ar' ? 'ar' : 'en',
          rtl: locale == 'ar' ? 'rtl' : 'ltr',
          rtl2: locale == 'ar' ? 'rtl.' : '',
          direction: locale == 'ar' ? 'right' : 'left',
        })
      }
      app.$cookies.set('Language', JSON.stringify(store.state.app.language), { parseJSON: false })


    } else {
      store.commit('app/SET_LANG', localLanguage)
    }
  }




  // Set locale

  store.dispatch('auth/checkLoggedIn')

  app.i18n.locale = store.state.app.language.locale

  if (route.fullPath === '/') {
    return redirect('/' + locale)
  }

  // checking user data and routes for page redirecting


  let loginData = app.$cookies.get('super-login-data', { parseJSON: true })
  var authlinks = ['branches', 'campaigns', 'questions', 'sections', 'settings', 'dashboard', 'chat', 'points', 'ratings', 'payment', 'requestes']
  var noauthlinks = ['login','restore-account', 'verify-account']

  var SecondRoute = route.path.split('/')[2] ? route.path.split('/')[2].toLowerCase() : ''
  if (SecondRoute == '') {
    if (loginData) {
      return redirect('/' + locale + '/dashboard')

    }
    else {

      return redirect('/' + locale + '/login')
    }
  }
  if (loginData) {

    if (noauthlinks.includes(SecondRoute)) {
      return redirect('/' + locale + '/dashboard')
    }
  } else {
    if (authlinks.includes(SecondRoute)) {
      return redirect('/' + locale + '/login')
    }
  }

}