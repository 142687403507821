export default {
  async loadAllAdvertisements({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `advertisements?page=${data.page}&per_page=${data.per_page}&state=${data.state
        }
        &best_period=${data.best_period}
        &advertisors=${data.advertisors}
        &range_from=${data.range_from}&range_to=${data.range_to}&search=${data.search ? data.search : ""}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAllAdvertisements", respo.data);
          commit("setAdvertisors", respo.data.data.advertisors);


        }
      })

  },
  async exportAdsToExcel({ commit, dispatch }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(
        `advertisements/export?page=${data.page}&per_page=${data.per_page}&state=${data.state
        }
        &type=${data.type}
        &best_period=${data.best_period}
        &advertisors=${data.advertisors}
      &range_from=${data.range_from}&range_to=${data.range_to}&search=${data.search ? data.search : ""}`,
        null,
        {
          responseType: 'blob'
        }
      )
      .then((respo) => {
        if (respo.status === 200) {
          if (data.type == 'xlsx') {
            const link = document.createElement('a');
            link.download = data.fileName + '_' + this.$moment().format('YYYY-MM-DD') + '.' + data.type;
            link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + respo.data;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            // todo: download pdf
            const link = document.createElement('a');
            link.download = data.fileName + '_' + this.$moment().format('YYYY-MM-DD') + '.' + data.type;
            link.href =  respo.data;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

          }


        }
      })

  },
  async loadAdvertisementsStores({ commit }, filters) {
    commit("app/loadingStart", null, { root: true });

   return await this.$axios
      .get(
        `advertisements/stores?${filters}`,
        null
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setAdvertisementsStores", respo.data.data.content);
          return respo.data.data.content

        }
      })

  },
  async loadOneAdvertisement({ commit }, id) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .get(`advertisements/${id}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setOneAdvertisement", respo.data.data);

        }
      })

  },
  async changeAdvertisementState({ commit }, id) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .put(`advertisements/change-state/${id}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          // Todo: change state of ads
          commit('setChangeAdvertisementState', {
            ...respo.data.data.content
          });
          if (respo.data.data.content && respo.data.data.content.state == 1) {
            commit("app/successMessage", "advertisement_activated", { root: true });

          }
          if (respo.data.data.content && respo.data.data.content.state == 2) {
            commit("app/successMessage", "advertisement_disabled", { root: true });

          }
        }
      })

  },
  async deleteOneAdvertisement({ commit }, id) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .delete(`advertisements/${id}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("users/setDeleteDialog", false, { root: true });
          commit("deleteAdvertisement", id);
          commit("app/successMessage", "advertisementDeleted", { root: true });

        }
      })

  },
  async updateAdvertisement({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .put(`/advertisements/${data.id}`, data)
      .then((respo) => {
        if (respo.status === 200) {
          this.$router.back();
          commit("app/successMessage", "advertisementUpdated", { root: true });

        }
      })

  },
  async createAdvertisement({ commit }, data) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .post(`/advertisements`, data)
      .then((respo) => {
        if (respo.status === 200) {
          this.$router.back();
          commit("app/successMessage", "advertisementCreated", { root: true });

        }
      })

  },
};
