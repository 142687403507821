export default {
  
  getTutorials(state) {
    return state.tutorials;
  },
  getTutorialsDetails(state) {
    return state.tutorialsDetails;
  },
  getTutorialsSections(state) {
    return state.tutorialsSections;
  },
  getTutorialsSectionsDetails(state) {
    return state.tutorialsSectionsDetails;
  },
}
