export default {
  setAllPackages: (state, data) => {
    state.allPackages = data.content;
  }, 
  setOnePackage: (state, data) => {
    state.onePackage = data.content;
  },
   
   
};
