<template>

  <v-app>
    <Nuxt />

    <messages />
  </v-app>

</template>

<script>
import { mapGetters } from "vuex";
import Messages from '../components/material/messages.vue';
export default {
  components: { Messages, },
  head() {
    return {
      htmlAttrs: {
        lang: this.language.locale,
        direction: this.language.rtl,
        dir: this.language.rtl,
        style: `direction: ${this.language.rtl}`,
      },

    };
  },

  computed: {
    ...mapGetters({ language: "app/getlang" }),
  },
  mounted() {
    this.$nextTick(() => {
      this.$nuxt.$loading.start();
      setTimeout(() => {
        this.$nuxt.$loading.finish();
        this.showcontent = true;
      }, 2000);
    });

  },
  methods: {

  }
}
</script>
