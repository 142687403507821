//import i18n from '~/plugins/i18n'
import Vue from "vue";
export default {
  setAllMessages: (state, data) => {
    state.allMessages = data;
  },
  // It checks if the storeMessages array is not empty, and if the first message's store_id matches the provided data's store_id, it adds the data to the storeMessages array. It then checks if the allMessages array is not empty, and if it contains a message with the same store_id, it updates the message and increments the number of unread messages. If there is no matching store_id, it adds the data to the allMessages array.
  pushMessage: (state, data) => {
    if (state.storeMessages.length > 0) {
      if (state.storeMessages[0].store_id == data.store_id) {
        state.storeMessages.push(data);
      }
    }
    if (state.allMessages.length > 0) {
      var currentStore = state.allMessages.findIndex((s) => s.store_id == data.store_id);
      if (currentStore > -1) {
        state.allMessages[currentStore].no_read_messages =
          state.allMessages[currentStore].no_read_messages + 1;
        state.allMessages[currentStore].message = data.message;
      }else{
         state.allMessages.push(data)
      }
    
    }
  },
  // this finds the index of the store in all messages, and sets the number of unread messages to 0 if the store is found.
  setStoreMessages: (state, data) => {
    state.storeMessages = data;
    var x = state.allMessages.findIndex(
      (s) => s.store_id == state.storeMessages[0].store_id
    );
    if (x > -1) state.allMessages[x].no_read_messages = 0;
  },
};
