export default {
  getAllAdvertisements (state) {
    return state.allAdvertisements
  },
  getOneAdvertisement (state) {
    return state.oneAdvertisement
  },
  getAdvertisementsStores (state) {
    return state.advertisementsStores
  },
  getAdvertisors (state) {
    return state.advertisors
  },
  
}
