export default {
  async loadAllCoupons({ commit },data) {
    commit('app/loadingStart', null, { root: true });
    await this.$axios.get(`coupons/subscription?page=${data.page}&per_page=${data.per_page}`, null).then((respo) => {
      if (respo.status === 200) {
        commit('setAllCoupons', respo.data.data);
      }
    });
  }, 
  async loadOneCoupon({ commit }, couponId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .get(`/coupons/subscription/${couponId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('setOneCoupon', respo.data.data);
        }
      });
  },
  async saveNewCoupon({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios.post(`/coupons/subscription`, data).then((respo) => {
      if (respo.status === 200) {
      
         
          this.$router.push({
            path: '/' + this.app.i18n.locale + '/coupons',
          });
        commit('app/successMessage', 'couponAdded', { root: true });
      }
    });
  },
  async editOneCoupon({ commit }, data) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .put(`/coupons/subscription/${data.couponId}`, data.couponDetails)
      .then((respo) => {
        if (respo.status === 200) {
       
            commit('UpdateCoupon', {
              ...respo.data.data.content
            });
            this.$router.push({
              path: '/' + this.app.i18n.locale + '/coupons',
            });
        
          commit('app/successMessage', 'couponUpdated', { root: true });
        }
      });
  },
  async deleteOneCoupon({ commit }, couponId) {
    commit('app/loadingStart', null, { root: true });

    await this.$axios
      .delete(`/coupons/subscription/${couponId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
         
          commit('deleteCoupon', couponId);
          commit('app/successMessage', 'couponDeleted', { root: true });
        }
      });
  },
  async changeCouponState({ commit }, couponId) {
    commit("app/loadingStart", null, { root: true });

    await this.$axios
      .put(`coupons/subscription/change-state/${couponId}`, null)
      .then((respo) => {
        if (respo.status === 200) {
          commit('UpdateCoupon', {
            ...respo.data.data.content
          });
        // show activation success message
          if(respo.data.data.content && respo.data.data.content.state==1){
            commit("app/successMessage", "coupon_activated", { root: true });

          }
        // show disabling success message
          if(respo.data.data.content && respo.data.data.content.state==2){
            commit("app/successMessage", "coupon_disabled", { root: true });

          }
        }
      })
      
  },
};
